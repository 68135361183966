import { useNavigate } from "react-router-dom";
import "./BlogCard.scss";
import { baseUrl } from "../../../../utils/apiData";
import { useEffect, useState } from "react";

const BlogCard = ({ data }) => {
  const [readTime, setReadTime] = useState(0);

  function getTextFromHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }

  function countWords(text) {
    return text.trim().split(/\s+/).length;
  }

  function calculateReadTime(wordCount) {
    const wordsPerMinute = 200;
    const minutes = Math.ceil(wordCount / wordsPerMinute);
    return minutes;
  }

  useEffect(() => {
    const textContent = getTextFromHTML(data?.content);
    const wordCount = countWords(textContent);
    const estimatedReadTime = calculateReadTime(wordCount);
    setReadTime(estimatedReadTime);
  }, [data?.content]);

  const navigate = useNavigate();
  return (
    <div className="blog_card" onClick={() => navigate(`/blogs/${data?.slug}`)}>
      <div className="img_wrap">
        <img src={`${baseUrl}/${data?.image}`} alt="blog" loading="lazy" />
      </div>
      <div className="text">
        <h4>{data?.name}</h4>
        <p className="des">{data?.subtitle}</p>
        <div className="category_line">
          <div className="category">
            <p className="p_second">Read more</p>
          </div>
          <p className="p_second">{readTime} min read</p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
