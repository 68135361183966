import "./Faq.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import Accordion from "react-bootstrap/Accordion";
import { Fragment, useContext } from "react";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import { webContext } from "../../../webContext/WebContext";

const Faq = ({faqData}) => {
  const { openContact } = useContext(webContext);


  return (
    <section className="faq p_b p_t">
      <div className="content_wrap">
        <ScrollAnimationSection>
          <div className="title">
            <h2 className="dark_title">
              Frequently asked <br /> <span>questions</span>
            </h2>
            <PrimaryBtn title="Ask your Question" onClick={openContact} />
          </div>
        </ScrollAnimationSection>

        <div className="faq_acc_wrap">
          <Accordion>
            {faqData?.length > 0 ? faqData?.map((data, j) => {
              return (
                <Fragment key={data.id}>
                  <Accordion.Item eventKey={`${j}`}>
                    <Accordion.Header>
                      <span className="v"></span> <span className="h"></span>{" "}
                      <p>{data?.q}</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>{data?.a}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Fragment>
              );
            }) : <p className="text-center">FAQ not found...</p>}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Faq;
