import { useState } from "react";
import WebLayout from "../../components/webLayout/WebLayout";
import AboutLanding from "./aboutLanding/AboutLanding";
import MidTab from "./midTab/MidTab";
import HomeCta from "../../pages/home/homeCta/HomeCta";

import { Helmet } from "react-helmet";
import { domain } from "../../utils/apiData";
const About = () => {
  const [extraH, setextraH] = useState(0);

  return (
    <WebLayout>
      <Helmet>
        <title>About | SupaGrow</title>
        <meta name="description" content="Supagrow About" />
        <link rel="canonical" href={`${domain}/about`} />
      </Helmet>

      <AboutLanding extraH={extraH} setextraH={setextraH} />
      <MidTab extraH={extraH} />
      <HomeCta title="We deliver our best to make your product better." />
    </WebLayout>
  );
};

export default About;
