import { NavLink } from "react-router-dom";
import "./Footer.scss";
import { paths } from "../../utils/path";
import { images } from "../../utils/images";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaBehance } from "react-icons/fa";
import { BsArrowUp } from "react-icons/bs";
import { Fragment, useState } from "react";

import { baseUrl, emailSubscribe } from "../../utils/apiData";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../textError/TextError";
import axios from "axios";

const Footer = () => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const companyTabs = [
    {
      id: 0,
      text: "Home",
      link: paths.home,
    },
    {
      id: 1,
      text: "About",
      link: paths.about,
    },
    {
      id: 2,
      text: "Services",
      link: paths.services,
    },
    {
      id: 3,
      text: "Clients",
      link: paths.clients,
    },
    {
      id: 4,
      text: "Case Studies",
      link: paths.caseStudies,
    },
    {
      id: 5,
      text: "Blogs",
      link: paths.blogs,
    },
  ];

  const servicesTabs = [
    {
      id: 0,
      text: "Web Development",
      link: paths.webDev,
    },
    {
      id: 1,
      text: "UI/UX",
      link: paths.uiux,
    },
    {
      id: 2,
      text: "SEO",
      link: paths.seo,
    },
    {
      id: 3,
      text: "Mobile App Development",
      link: paths.mobileApp,
    },
  ];

  const solutionsTabs = [
    {
      id: 0,
      text: "Ecommerce",
      link: paths.ecom,
    },
    {
      id: 1,
      text: "LMS",
      link: paths.lms,
    },
    {
      id: 2,
      text: "CRM",
      link: paths.crm,
    },
    {
      id: 3,
      text: "HRMS",
      link: paths.hrms,
    },
    {
      id: 4,
      text: "Cyber Security Product Development",
      link: paths.cyberSec,
    },
    {
      id: 5,
      text: "CMS",
      link: paths.cms,
    },
  ];

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email formate!")
      .required("Email is required!"),
  });

  const emailSubscribeApi = async (body) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${emailSubscribe}`, { ...body });
      if (res?.data?.success) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 20000);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const onSubmit = (values, { resetForm }) => {
    emailSubscribeApi(values);
    resetForm();
  };

  return (
    <footer>
      <section className="footer">
        <div className="content_wrap">
          <div className="footer_grid">
            <div className="logo_wrap">
              <a href="/">
                <img src={images.logo} alt="Brand Logo" loading="lazy" />
              </a>
              <div className="news_letter">
                <h5>Join our newsletter</h5>

                {success ? (
                  <div className="success_msg">
                    <p className="p_second">Email Sent Successfully!</p>
                  </div>
                ) : (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form>
                      <Field
                        type="text"
                        placeholder="Enter Email"
                        id="email"
                        name="email"
                      />
                      <ErrorMessage name="email" component={TextError} />
                      {loader ? (
                        <button type="button">Loader...</button>
                      ) : (
                        <button type="submit">Submit</button>
                      )}
                    </Form>
                  </Formik>
                )}
              </div>
            </div>
            <div className="link_col">
              <h5>Company</h5>
              {companyTabs.map((data) => {
                return (
                  <Fragment key={data?.id}>
                    <NavLink to={data?.link}>{data?.text}</NavLink>
                  </Fragment>
                );
              })}
            </div>

            <div className="link_col">
              <h5>Services</h5>
              {servicesTabs.map((data) => {
                return (
                  <Fragment key={data?.id}>
                    <NavLink to={data?.link}>{data?.text}</NavLink>
                  </Fragment>
                );
              })}
            </div>

            <div className="link_col">
              <h5>Solutions</h5>
              {solutionsTabs.map((data) => {
                return (
                  <Fragment key={data?.id}>
                    <NavLink to={data?.link}>{data?.text}</NavLink>
                  </Fragment>
                );
              })}
            </div>

            <div className="link_col social_col">
              <h5>Connect</h5>
              <div className="social_icons">
                <a
                  href="https://in.linkedin.com/company/supagrow"
                  target="_blank"
                >
                  <FaLinkedinIn />
                </a>
                <a href="https://www.instagram.com/supagrow/" target="_blank">
                  <FaInstagram />
                </a>

                <a href="https://www.behance.net/supagrow" target="_blank">
                  <FaBehance />
                </a>
                <a href="https://twitter.com/supagrow_" target="_blank">
                  <FaXTwitter />
                </a>
                <a href="https://www.facebook.com/supagrow01/" target="_blank">
                  <FaFacebookF />
                </a>
              </div>

              <button type="button" className="go_top" onClick={goTop}>
                <BsArrowUp />
                <div className="slides"></div>
              </button>
            </div>
          </div>
          <div className="copy_right">
            <p className="p_second">© Supagrow All Rights Reserved.</p>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
