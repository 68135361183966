import "./ArrowBtn.scss";
import { SlArrowRight } from "react-icons/sl";

const ArrowBtn = ({ onClick }) => {
  return (
    <button className="hover_arrow_btn" onClick={onClick}>
      <SlArrowRight />
      <span>
        <SlArrowRight />
      </span>
    </button>
  );
};

export default ArrowBtn;
