import "./AboutLanding.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { Fragment, useEffect, useRef, useState } from "react";
import { images } from "../../../utils/images";
import Marquee from "react-fast-marquee";
import useScrollPosition from "../../../utils/hooks/useScrollPosition";
import { clientsLogo } from "../../../utils/clients";

const AboutLanding = ({ extraH, setextraH }) => {
  const goTo = (secId) => {
    const section = document.getElementById(secId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop + extraH + 250,
        behavior: "smooth",
      });
    }
  };

  const H = window.innerHeight;
  const W = window.innerWidth;

  const scrollPosition = useScrollPosition();

  const sectionRef = useRef(null);

  useEffect(() => {
    if (sectionRef.current) {
      setextraH(sectionRef.current.offsetHeight);
    }
  }, []);

  return (
    <div className="about_landing_wrap p_t" ref={sectionRef}>
      <section className="about_landing">
        <div className="content_wrap">
          <h1>Your Vision,</h1>
          <h1>
            <span>Our Design & Code</span>
          </h1>
          <PrimaryBtn
            varient="dark"
            title="Explore Services"
            onClick={() => goTo("secA")}
          />
        </div>
      </section>
      <section className="logo_slide p_t p_b">
        <Marquee>
          {clientsLogo?.map((data) => {
            return (
              <div className="img_wraper" key={data.id}>
                <img src={data.img} alt="logo" loading="lazy" />
              </div>
            );
          })}
        </Marquee>
      </section>

      <div
        className="animated_circle desktop"
        style={{
          width: `calc(150px + ${(scrollPosition / H) * 100}%)`,
          height: `calc(150px + ${(scrollPosition / H) * 100}%)`,
          right: `${80 - scrollPosition / 8}px`,
        }}
      >
        <img
          src={images.favicon}
          alt="Brand Logo"
          loading="lazy"
          style={{ opacity: `${(H - scrollPosition) / (H - 100)}` }}
        />
      </div>
      <div
        className="animated_circle mobile"
        style={{
          width: `calc(100px + ${(scrollPosition / H) * 100}%)`,
          height: `calc(100px + ${(scrollPosition / H) * 100}%)`,
          right: `${16 - scrollPosition / 16}px`,
        }}
      >
        <img
          src={images.favicon}
          alt="Brand Logo"
          loading="lazy"
          style={{ opacity: `${(H - scrollPosition) / (H - 100)}` }}
        />
      </div>

      <section className="about_second">
        <div className="content_wrap p_t p_b">
          <div className="bi_sec">
            <div className="text">
              <h2 className="dark_title">About Us</h2>
              <p>
                Supagrow is popular among its clients for its cutting edge
                designs and its ability to translate the clients’ vision for the
                company beautifully with a very strong and well-defined Ul/UX
                Process. Our website, mobile applications and custom software
                are very well adopted in the market because of our expertise and
                deep research which goes into what products and features work in
                specific industries.
              </p>
            </div>
            <div className="img_sec">
              <img src={images.aboutA} alt="About" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutLanding;
