import React, { useRef, useState, useEffect } from "react";

import "intersection-observer";

const CardAnim = ({children}) => {
  const sectionRef = useRef(null);
  const [opacity, setOpacity] = useState(0.2);
  const [scalex, setScalex] = useState(0.8);

  useEffect(() => {
    const section = sectionRef.current;

    const handleScroll = () => {
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top >= 0 && rect.bottom <= windowHeight) {
        // Element is completely in view
        setOpacity(1);
        setScalex(1);
      } else if (rect.top < windowHeight && rect.bottom > 0) {
        // Element is partially in view
        const distanceFromBottom = windowHeight - rect.top;
        const newOpacity = Math.min(1, 0.2 + (distanceFromBottom / 400) * 0.8);
        const newscale = Math.min(1, 0.8 + (distanceFromBottom / 400) * 0.4);
        setOpacity(newOpacity);
        setScalex(newscale);
      } else {
        // Element is out of view
        setOpacity(0.2);
        setScalex(0.8);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          window.addEventListener("scroll", handleScroll);
        } else {
          window.removeEventListener("scroll", handleScroll);
        }
      },
      { threshold: [0, 1] }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <div
      className="img_sec"
      ref={sectionRef}
      style={{
        opacity: opacity,
        transform: `scale(${scalex})`,
        transition: "all 0.6s ease-in-out",
      }}
    >
    {children}
    </div>
  );
};

export default CardAnim;
