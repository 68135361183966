import { images } from "./images";

export const caseStudiesData = [
  {
    id: "hunter",
    small_title: "UX Design and Web Development for",
    title: "Hunter by Knogin",
    title_para:"Knogin, a leader in cybersecurity solutions, approached us to revamp the website for their product: Hunter. Hunter is a cutting-edge tool designed to consolidate alerting and noisy tools into a user-friendly storyboard. Leveraging AI, Hunter seamlessly integrates into multi-cloud environments and any system generating flow data. It analyzes alerts, prioritizes them, and presents users with a well-organized incident response plan.",    
    client_overview:
      "Knogin, a leader in cybersecurity solutions, approached us to revamp the website for their product: Hunter. Hunter is a cutting-edge tool designed to consolidate alerting and noisy tools into a user-friendly storyboard. Leveraging AI, Hunter seamlessly integrates into multi-cloud environments and any system generating flow data. It analyzes alerts, prioritizes them, and presents users with a well-organized incident response plan.",
    caseInfo: images.hunterCase,
    img2: images.hunterUi,

    challenges: [
      {
        id: 0,
        title: "Outdated Website Design:",
        para: "Knogin's existing website did not effectively communicate the sophistication and innovation of the Hunter product.",
      },
      {
        id: 1,
        title: "Creativity Focus:",
        para: "The client emphasized the need for a creative and modern design that aligns with the advanced nature of the Hunter tool.",
      },
      {
        id: 2,
        title: "Quick Turnaround:",
        para: "As the client desired a prompt turnaround, our challenge was to balance speed with a meticulous UX design process.",
      },
    ],

    approach: [
      {
        id: 0,
        title: "Comprehensive Product Understanding:",
        para: "Our team delved into understanding the intricacies of the Hunter product. We conducted workshops with Knogin's experts to grasp the technical aspects and unique selling points of Hunter.",
      },
      {
        id: 1,
        title: "User Persona Development:",
        para: "Knowing the product's audience was crucial. We created detailed user personas to guide our design decisions, ensuring the revamped website would resonate with cybersecurity professionals.",
      },
      {
        id: 2,
        title: "Wireframing and Prototyping:",
        para: "The UX design process commenced with wireframing and prototyping. We mapped out the user journey and created interactive prototypes to visualize the flow and functionalities of the new website.",
      },
      {
        id: 3,
        title: "Creative Design Implementation:",
        para: "Keeping the client's emphasis on creativity in mind, our design team crafted visually striking interfaces. Modern design elements and intuitive navigation were incorporated to enhance the user experience.",
      },
      {
        id: 4,
        title: "Responsive Development:",
        para: "Hunter's diverse user base required a responsive design. Our development phase ensured that the website functioned seamlessly across various devices and screen sizes.",
      },
      {
        id: 5,
        title: "Iterative Feedback Loops:",
        para: "Regular feedback sessions with Knogin allowed us to make iterative improvements. This collaborative approach ensured the final product met the client's expectations.",
      },
      {
        id: 6,
        title: "Speedy Turnaround:",
        para: "Recognizing the client's need for a quick turnaround, we optimized our processes without compromising the quality of UX design and development.",
      },
    ],
    result: [
      {
        id: 0,
        title: "Enhanced User Experience:",
        para: "The revamped website offers an enhanced user experience, making it easier for cybersecurity professionals to understand, navigate, and appreciate the Hunter product.",
      },
      {
        id: 1,
        title: "Modern Design Aesthetics:",
        para: "The modern design aligns with the advanced features of Hunter, creating a cohesive and visually appealing online presence.",
      },
      {
        id: 2,
        title: "Positive Client Feedback:",
        para: "Knogin expressed satisfaction with the creativity and speed of the revamp, stating that the website now effectively showcases the capabilities of the Hunter product.",
      },
      {
        id: 3,
        title: "Conclusion:",
        para: "Our collaboration with Knogin on the Hunter product showcases our commitment to merging creativity with functionality in the realm of UX design and web development. The revamped website not only aligns with Knogin's vision but also elevates the overall user experience for Hunter's audience.",
      },
    ],
    Conclusion:
      "Our collaboration with Knogin on the Hunter product showcases our commitment to merging creativity with functionality in the realm of UX design and web development. The revamped website not only aligns with Knogin's vision but also elevates the overall user experience for Hunter's audience.",
    company: "Knogin",
    industry: "Cyber Security",
    location: "Ireland",
    website: "https://knogin.com/",
  },

  {
    id: "gate8",
    small_title: "UX Design and Web Development for",
    title: "Gate8",
    title_para:"Gate8, a leading global supplier of crew luggage to airlines worldwide, approached our team to revamp their online presence. The client emphasized the importance of creativity and sought a website that not only showcased their product range but also delivered an immersive user experience.",
    client_overview:
      "Gate8, a leading global supplier of crew luggage to airlines worldwide, approached our team to revamp their online presence.  The client emphasized the importance of creativity and sought a website that not only showcased their product range but also delivered an immersive user experience.",
    caseInfo: images.gate8Case,
    img2: images.gate8Ui,

    challenges: [
      {
        id: 0,
        title: "Outdated Website: ",
        para: "Gate8's existing website lacked modern aesthetics and failed to effectively communicate the brand's innovation.",
      },
      {
        id: 1,
        title: "User Engagement:",
        para: "The client desired a website that would not only showcase products but also engage users effectively, leading to increased conversion rates.",
      },
      {
        id: 2,
        title: "Global Appeal:",
        para: "Given their international clientele, the website needed to be user-friendly and resonate with a diverse audience.",
      },
    ],

    approach: [
      {
        id: 0,
        title: "Discovery Phase:",
        para: "We initiated the project with a comprehensive discovery phase, understanding Gate8's brand, target audience, and competitors. Engaging in collaborative workshops, we defined the project scope, objectives, and key performance indicators.",
      },
      {
        id: 1,
        title: "User Persona Development:",
        para: "Through extensive market research and user interviews, we created detailed user personas. Understanding the unique needs of airline crew members guided our design decisions, ensuring a user-centric approach.",
      },
      {
        id: 2,
        title: "⁠Wireframing and Prototyping:",
        para: "Our team translated conceptual ideas into tangible wireframes and prototypes. This step allowed Gate8 to visualize the website's structure and flow, ensuring alignment with their vision.",
      },
      {
        id: 3,
        title: "⁠Creative Design:",
        para: "Gate8's emphasis on creativity was embraced in the design phase. We crafted visually appealing interfaces, incorporating elements that reflected the brand's commitment to innovation and quality.",
      },
      {
        id: 4,
        title: "Responsive Development:",
        para: "Leveraging the latest technologies, we implemented a responsive design approach to ensure a seamless user experience across various devices and screen sizes.",
      },
      {
        id: 5,
        title: "⁠User Testing and Feedback:",
        para: "A crucial step in the UX design process involved user testing. We gathered feedback from Gate8 stakeholders and potential users, iterating on the design to address any concerns and enhance user satisfaction.",
      },
      {
        id: 6,
        title: "Launch and Continuous Improvement:",
        para: "The website was successfully launched, incorporating captivating visuals, intuitive navigation, and informative content. Post-launch, we continue to monitor user behavior, gathering data for further enhancements and optimizations.",
      },
    ],
    result: [
      {
        id: 0,
        title: " ⁠Enhanced User Engagement:",
        para: "   The new website significantly increased user engagement, with improved time spent on pages and a reduction in bounce rates.",
      },
      {
        id: 1,
        title: "⁠Global Appeal:",
        para: " Gate8's global audience responded positively to the revamped website, reflecting in increased international inquiries and orders.",
      },
      {
        id: 2,
        title: "⁠Positive Client Feedback:",
        para: "Gate8 expressed satisfaction with the creative design, user-centric approach, and the overall outcome of the project.",
      },
    ],
    Conclusion:
      "The collaboration with Gate8 exemplifies our commitment to delivering not just a website but a tailored digital experience. By seamlessly integrating creativity with the UX design process, we successfully transformed Gate8's online presence, contributing to their global success in the airline industry.",
    company: "Gate 8",
    industry: "Manufacturing",
    location: "UK",
    website: "https://gate8-luggage.co.uk/",
  },

  {
    id: "blue_bricks",
    small_title: "UX Design and Web Development for",
    title: "Bluebricks",
    title_para: "Bluebricks, a pioneering company based in Australia, is on a mission to revolutionize the realms of cybersecurity, payment solutions, and digital transformation automation products. Their commitment to innovation and successful commoditization led them to seek a robust web portal for client information sharing. They approached us to design and develop a website and a CMS web portal aligned with their vision.",
    
    client_overview:
      "Bluebricks, a pioneering company based in Australia, is on a mission to revolutionize the realms of cybersecurity, payment solutions, and digital transformation automation products. Their commitment to innovation and successful commoditization led them to seek a robust web portal for client information sharing. They approached us to design and develop a website and a CMS web portal aligned with their vision.",
    caseInfo: images.bluebricksLanding,
    img2: images.blueBricksUi,

    challenges: [
      {
        id: 0,
        title: "Innovative Image:",
        para: "Bluebricks, as an innovator, needed a website that reflected their cutting-edge approach in the fields of cybersecurity, payment solutions, and digital transformation.",
      },
      {
        id: 1,
        title: "Client Information Portal:",
        para: "Building a secure, intuitive, and feature-rich CMS web portal for seamless client information sharing was a primary requirement.",
      },
      {
        id: 2,
        title: "Australian Market Focus:",
        para: "Understanding the specific needs and preferences of the Australian market was crucial for effective UX design.",
      },
    ],

    approach: [
      {
        id: 0,
        title: "Discovery Workshops:",
        para: "We conducted comprehensive discovery workshops with Bluebricks to understand their mission, values, and specific requirements for the CMS web portal.",
      },
      {
        id: 1,
        title: "Competitor Analysis:",
        para: "Analyzing competitors in the Australian market helped us identify opportunities for differentiation and innovation in the UX design and web development process.",
      },
      {
        id: 2,
        title: "User-Centric Design:",
        para: "Creating user personas allowed us to tailor the UX design to the preferences and behaviors of Bluebricks' target audience. We focused on simplicity, efficiency, and visual appeal.",
      },
      {
        id: 3,
        title: "Innovative Visual Design:",
        para: "Our design team crafted a visually striking interface that conveyed Bluebricks' innovative spirit. Modern design elements were incorporated to ensure a contemporary and appealing look.",
      },
      {
        id: 4,
        title: "Secure CMS Development:",
        para: "Developing the CMS web portal involved implementing robust security features to safeguard client information. User authentication, data encryption, and secure communication protocols were prioritized.",
      },
      {
        id: 5,
        title: "Feature-Rich Functionality:",
        para: "The web portal was designed to offer a seamless client information sharing experience. Features included secure document uploads, real-time collaboration tools, and personalized user dashboards.",
      },
      {
        id: 6,
        title: "Responsive Design:",
        para: "Given the diverse range of devices used by Bluebricks' clients, we ensured the CMS web portal was fully responsive, offering a consistent and user-friendly experience across various screens.",
      },
      {
        id: 7,
        title: "Agile Development Process:",
        para: "Employing an agile development process allowed for iterative improvements based on continuous feedback from Bluebricks. This ensured the final product met their evolving expectations.",
      },
    ],
    result: [
      {
        id: 0,
        title: "Innovative Online Presence:",
        para: "The website successfully portrays Bluebricks as an innovative force in the cybersecurity, payment solutions, and digital transformation sectors.",
      },
      {
        id: 1,
        title: "Secure Client Information Sharing:",
        para: "The CMS web portal provides a secure and efficient platform for Bluebricks' clients to share information, fostering collaboration and communication.",
      },
      {
        id: 2,
        title: "Positive Client Feedback:",
        para: "Bluebricks expressed satisfaction with the UX design and web development, highlighting the website's alignment with their mission and its positive impact on client interactions.",
      },
    ],
    Conclusion:
      "Our collaboration with Bluebricks exemplifies our commitment to translating a client's mission and values into a compelling online presence. The UX design and web development efforts not only showcase Bluebricks' innovation but also provide a secure and intuitive platform for client information sharing, contributing to their success in the Australian market.",
    company: "Blue-bricks",
    industry: "Cyber Security",
    location: "Australia",
    website: "https://www.blue-bricks.com/",
  },
];
