import { createContext, useState } from "react";

export const webContext = createContext();

const WebContextProvider = ({ children }) => {
  const [contactStatus, setContactStatus] = useState(false);
  const [loader, setLoader] = useState(true);

  const startLoader = () => {
    setLoader(true);
  };

  const closeLoader = () => {
    setLoader(false);
  };

  const openContact = () => {
    setContactStatus(true);
  };

  const closeContact = () => {
    setContactStatus(false);
  };

 
  const data = {
    contactStatus,
    openContact,
    closeContact,

    loader,
    startLoader,
    closeLoader,
  };

  return <webContext.Provider value={data}>{children}</webContext.Provider>;
};

export default WebContextProvider;
