import { Fragment, useEffect, useRef, useState } from "react";
import "./FeedBackSlide.scss";
import Slider from "react-slick";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";

import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import TestimonialCard from "../../../components/testimonialCard/TestimonialCard";

import { testimonial } from "../../../utils/testimonial";

import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { useNavigate } from "react-router-dom";

const FeedBackSlide = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    beforeChange: (current, next) => {
      setCurrentSlide(next);

      if (next == testimonial.length - 1) {
        setActive(-1);
      } else {
        setActive(next);
      }
    },

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const slideRef = useRef(null);

  const goNext = () => {
    slideRef.current.slickNext();
  };

  const goPrev = () => {
    slideRef.current.slickPrev();
  };

  return (
    <Fragment>
      <section className="feed_back_slide p_t">
        <div className="content_wrap">
          <ScrollAnimationSection>
            <div className="title">
              <h2>What our clients say</h2>
            </div>
          </ScrollAnimationSection>
        </div>
      </section>
      <div className="padding_cover p_top">
        <div id="feed_slider_wrap">
          <Slider ref={slideRef} {...settings}>
            {testimonial?.map((data, j) => {
              return (
                <div className="slides_wrap" key={data.id}>
                  <TestimonialCard data={data} index={j} active={active} />
                </div>
              );
            })}
          </Slider>

          <button type="button" className="prev_btn" onClick={goPrev}>
            <span
              className="hovered"
              style={
                currentSlide % 3 === 1
                  ? { left: "-50%" }
                  : currentSlide % 3 === 2
                  ? { left: "50%" }
                  : { left: "120%" }
              }
            >
              <TfiAngleLeft />
            </span>
            <span
              className="hovered"
              style={
                currentSlide % 3 === 2
                  ? { left: "-20%" }
                  : currentSlide % 3 === 0
                  ? { left: "50%" }
                  : { left: "120%" }
              }
            >
              <TfiAngleLeft />
            </span>
            <span
              className="hovered"
              style={
                currentSlide % 3 === 0
                  ? { left: "-20%" }
                  : currentSlide % 3 === 1
                  ? { left: "50%" }
                  : { left: "120%" }
              }
            >
              <TfiAngleLeft />
            </span>

            <span className="fixed">
              <TfiAngleLeft />
            </span>
          </button>
          <button type="button" className="next_btn" onClick={goNext}>
            <span
              className="hovered"
              style={
                currentSlide % 3 === 1
                  ? { left: "-20%" }
                  : currentSlide % 3 === 2
                  ? { left: "50%" }
                  : { left: "120%" }
              }
            >
              <TfiAngleRight />
            </span>
            <span
              className="hovered"
              style={
                currentSlide % 3 === 2
                  ? { left: "-20%" }
                  : currentSlide % 3 === 0
                  ? { left: "50%" }
                  : { left: "120%" }
              }
            >
              <TfiAngleRight />
            </span>
            <span
              className="hovered"
              style={
                currentSlide % 3 === 0
                  ? { left: "-20%" }
                  : currentSlide % 3 === 1
                  ? { left: "50%" }
                  : { left: "120%" }
              }
            >
              <TfiAngleRight />
            </span>

            <span className="fixed">
              <TfiAngleRight />
            </span>
          </button>
        </div>
      </div>
      <section className="progress_sec p_top p_b">
        <div className="content_wrap">
          <div className="progress_bar">
            <div
              className="filler"
              style={{
                width: `${(currentSlide * 100) / (testimonial.length - 1)}%`,
              }}
            ></div>
          </div>
          <div className="btn_sec">
            <PrimaryBtn
              varient="dark"
              title="View Clients"
              onClick={() => navigate("/clients")}
            />
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default FeedBackSlide;
