import { useState } from "react";
import "./OfferingPill.scss";
import { MdAdd } from "react-icons/md";
import { motion } from "framer-motion";

const OfferingPill = ({ data }) => {
  const [status, setStatus] = useState(false);

  return (
    <div
      className={`offering_pill ${status ? "active" : ""}`}
      onClick={() => setStatus((prev) => !prev)}
    >
      <div className="title_wrap">
        <div className="left">
          <h2 className="num">{data.id}</h2>
          <h2>{data.title}</h2>
        </div>
        <div className="right">
        <p className="second">Learn more</p>
          <button type="button">
            <div className="white">      
              <MdAdd />
            </div>
            <MdAdd />
          </button>
        </div>
      </div>
      {status && (
        <motion.div
          initial={{ scaleY:0, opacity: 0 }}
          whileInView={{ scaleY:1, opacity: 1 }}
          transition={{ duration: 0.4, ease: "easeOut" }}
          className="bi_grid"
        >
         <p>{data.text}</p>
        </motion.div>
      )}
    </div>
  );
};

export default OfferingPill;
