import { NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import PrimaryBtn from "../primaryBtn/PrimaryBtn";
import { useContext } from "react";
import { webContext } from "../../webContext/WebContext";
import { IoMdMenu } from "react-icons/io";
import { images } from "../../utils/images";
import ServicesDd from "./servicesDd/ServicesDd";
import { MdKeyboardArrowDown } from "react-icons/md";
import SolutionsDd from "./soloutionsDd/SolutionsDd";
import { paths } from "../../utils/path";

const Header = ({ openSidebar }) => {
  const location = useLocation();

  const servicesPaths = [paths.webDev, paths.uiux, paths.seo, paths.mobileApp];
  const solutionsPaths = [
    paths.ecom,
    paths.lms,
    paths.crm,
    paths.hrms,
    paths.cyberSec,
    paths.cms,
  ];

  const { contactStatus, openContact, closeContact } = useContext(webContext);
  return (
    <header>
      <section className="main_header">
        <div className="content_wrap grids">
          <div className="logo_wrap">
            <a href="/">
              <img src={images.logo} alt="Brand Logo" loading="lazy" />
            </a>
            <button type="button" onClick={openSidebar}>
              <IoMdMenu />
            </button>
          </div>
          <nav>
            <div className="tab_dd">
              <p
                className={
                  servicesPaths.includes(location?.pathname) ? "active" : ""
                }
              >
                Services
                <span>
                  <MdKeyboardArrowDown />
                </span>
              </p>
              <div className="dd_panel">
                <ServicesDd />
              </div>
            </div>

            <div className="tab_dd">
              <p className={
                  solutionsPaths.includes(location?.pathname) ? "active" : ""
                }>
                Solutions
                <span>
                  <MdKeyboardArrowDown />
                </span>
              </p>
              <div className="dd_panel">
                <SolutionsDd />
              </div>
            </div>

            <NavLink to="/casestudies">Case Studies</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/blogs">Blogs</NavLink>
          </nav>
          <div className="contact_side">
            <PrimaryBtn title="Get in Touch" onClick={openContact} />
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
