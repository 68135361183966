import "./BlogDetailLanding.scss";
import { baseUrl } from "../../../utils/apiData";
import { useEffect, useState } from "react";
import htmlParser from "html-react-parser";

const BlogDetailLanding = ({ blog }) => {
  const FormattedDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };


  const [readTime, setReadTime] = useState(0);

  function getTextFromHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }

  function countWords(text) {
    return text.trim().split(/\s+/).length;
  }

  function calculateReadTime(wordCount) {
    const wordsPerMinute = 200;
    const minutes = Math.ceil(wordCount / wordsPerMinute);
    return minutes;
  }

  useEffect(() => {
    const textContent = getTextFromHTML(blog?.content);
    const wordCount = countWords(textContent);
    const estimatedReadTime = calculateReadTime(wordCount);
    setReadTime(estimatedReadTime);
  }, [blog?.content]);



  return (
    <section className="blog_detail_landing">
      <div className="content_wrap p_top">
        <div className="category_line">
          {/* <div className="category">
            <p className="p_second">{blog?.author}</p>
          </div> */}
          <p className="p_second">{readTime} min read</p>
        </div>
        <h2 className="dark_title">{blog?.name}</h2>
        <div className="img_wrap">
          <img src={`${baseUrl}/${blog?.image}`} alt="blog" loading="lazy" />
        </div>
        <div className="social_line">
          <div className="left">
            <label>Last updated on</label>
            <p className="p_second">{FormattedDate(blog?.updatedAt)}</p>
          </div>
          <div className="right"></div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailLanding;
