import { images } from "./images";

export const caseStudyList = [
    {
      id: "hunter",
      img: images.hunterCase,
      tags: ["Design", "Development"],
      title: "UX Design and Web Development for Hunter by Knogin",
      shortTitle:"Hunter by Knogin",
      name: "Cyber Security",
      date: "DEC 28, 2023",
    },
    {
      id: "gate8",
      img: images.gate8Case,
      tags: ["Design", "Development"],
      title: "UX Design and Web Development for Gate8",
      shortTitle:"Gate8",
      name: "E-Commerce",
      date: "DEC 28, 2023",
    },
    {
      id: "blue_bricks",
      img: images.blueBricksCase,
      tags: ["Design", "Development"],
      title: "UX Design and Web Development for Bluebricks",
      shortTitle:"Bluebricks",
      name: "Cyber Security",
      date: "DEC 28, 2023",
    },
  ];