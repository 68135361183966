import "./HomeBlogSec.scss";
import BlogCard from "../../blogs/blogsList/blogCard/BlogCard";
import { Fragment, useEffect, useState } from "react";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { useNavigate } from "react-router-dom";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import { baseUrl, getBlog } from "../../../utils/apiData";
import axios from "axios";

const HomeBlogSec = () => {

  const navigate = useNavigate();

  const [blogList, setBlogList] = useState([]);
  const [loader, setLoader] = useState(false);

  const getBlogApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getBlog}`);
      if (res?.data?.success) {
        setBlogList(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getBlogApi();
  }, []);

  return (
    <section className="home_blog_sec p_b">
      <div className="content_wrap">
        <ScrollAnimationSection>
          <div className="title">
            <h2 className="dark_title">
              Latest <span>Blogs</span>
            </h2>
            <PrimaryBtn title="View All" onClick={() => navigate("/blogs")} />
          </div>
        </ScrollAnimationSection>

        {blogList?.length > 0 ? (
          <div className="tri_sec p_top">
            {blogList?.slice(0, 3)?.map((data) => {
              return (
                <Fragment key={data._id}>
                  <BlogCard data={data} />
                </Fragment>
              );
            })}
          </div>
        ) : (
          <p className="text-center">Blog not found...</p>
        )}
      </div>
    </section>
  );
};

export default HomeBlogSec;
