import { Fragment, useContext, useState } from "react";
import "bootstrap/dist/js/bootstrap.js";

import Header from "../header/Header";
import ContactSidebar from "./contactSidebar/ContactSidebar";
import Footer from "../footer/Footer";
import WebSidebar from "../webSidebar/WebSidebar";
import MainLoader from "../mainLoader/MainLoader";
import { webContext } from "../../webContext/WebContext";

const WebLayout = ({ children }) => {
  const [sidebarStatus, setSidebarStatus] = useState(false);

  const { loader } = useContext(webContext);

  const openSidebar = () => {
    setSidebarStatus(true);
  };

  const closeSidebar = () => {
    setSidebarStatus(false);
  };

  return (
    <Fragment>
      {loader && <MainLoader />}

      {!loader && (
        <Fragment>
          <Header openSidebar={openSidebar} />
          <WebSidebar
            sidebarStatus={sidebarStatus}
            closeSidebar={closeSidebar}
          />
          <ContactSidebar />
          {children}
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};
export default WebLayout;
