import "./BlogContent.scss";
import {images} from "../../../utils/images";

const BlogContent = ({blog}) => {
    return(
        <section className="blog_content p_top p_b">
            <div className="content_wrap">

            <div dangerouslySetInnerHTML={{ __html: blog?.content }}></div>

             
            </div>
        </section>
    )
}

export default BlogContent;