import { useLocation } from "react-router-dom";
import WebLayout from "../../components/webLayout/WebLayout";
import ClientList from "./clientsList/ClientList";
import { Helmet } from "react-helmet";
import { domain } from "../../utils/apiData";

const Clients = () => {
  const location = useLocation();
  console.log(location)
  return (
    <WebLayout>
       <Helmet>
        <title>Clients | SupaGrow</title>
        <meta name="description" content="Our Clients" />
        <link rel="canonical" href={`${domain}/clients`} />
      </Helmet>
      <ClientList />
    </WebLayout>
  );
};
export default Clients;
