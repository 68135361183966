import useScrollPosition from "../../../utils/hooks/useScrollPosition";
import "./CaseDetailLanding.scss";

const CaseDetailLanding = ({data}) => {
  const scrollPosition = useScrollPosition();

  return (
    <section
      className="case_detail p_t p_b"
      style={{ opacity: `${scrollPosition > 1500 ? "0" : ""}` }}
    >
      <div
        className="content_wrap"
        style={{ opacity: `${50 / scrollPosition}` }}
      >
        <p>{data?.small_title}</p>
        <h1>{data?.title}</h1>
      </div>
    </section>
  );
};

export default CaseDetailLanding;
