import "./ErrorPage.scss";
import WebLayout from "../../components/webLayout/WebLayout";
import Four from "./four/Four";
import Zero from "./zero/Zero";
import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../components/primaryBtn/PrimaryBtn";
import { Helmet } from "react-helmet";
import { domain } from "../../utils/apiData";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <WebLayout>
      <Helmet>
        <title>SupaGrow</title>
        <meta name="description" content="Error Page" />
        <link rel="canonical" href={`${domain}/*`} />
      </Helmet>
      <section className="error_page">
        <div className="content_wrap p_t p_b">
          <div className="number_wrap">
            <Four />
            <Zero />
            <Four />
          </div>
          <div className="error_content">
            <h1>Oops!</h1>
            <h3>Tha page was not found.</h3>
            <PrimaryBtn
              varient="dark"
              title="Go to Home"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </section>
    </WebLayout>
  );
};

export default ErrorPage;
