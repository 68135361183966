import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import "./HomeLanding.scss";
import Marquee from "react-fast-marquee";
import { images } from "../../../utils/images";
import { Fragment, useEffect, useRef, useState } from "react";

import useScrollPosition from "../../../utils/hooks/useScrollPosition";

const HomeLanding = () => {
  const scrollPosition = useScrollPosition();
  const [blur, setBlur] = useState(false);

  useEffect(() => {
    const handleScrollEvent = () => {
      const viewportHeight = window.innerHeight;
      if (scrollPosition >= viewportHeight) {
        setBlur(true);
      } else {
        setBlur(false);
      }
    };

    handleScrollEvent();
  }, [scrollPosition]);

  const goTo = (secId) => {
    const section = document.getElementById(secId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="home_landing">
      <div className="content_wrap p_top p_b">
        <div className="content" style={{ opacity: scrollPosition > 0 && `${200 / scrollPosition}` }}>
          <h1>Crafting User-Centric Digital Journeys</h1>
          <p>
            We are your strategic partner in harnessing the power of the digital
            landscape. Our mission is to transform your online presence into a
            captivating and functional platform that drives results and enhances
            user experiences.
          </p>
          <PrimaryBtn
            title="Explore Portfolio"
            onClick={() => goTo("portfolio")}
          />
        </div>
      </div>
    </section>
  );
};

export default HomeLanding;
