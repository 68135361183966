import { Fragment, useEffect, useRef, useState } from "react";
import useScrollPosition from "../../../utils/hooks/useScrollPosition";
import "./Challenges.scss";
import { Parallax } from "react-scroll-parallax";
import useViewPortHeight from "../../../utils/hooks/useViewPortHeight";

const Challenges = ({ data }) => {
  const sectionRef = useRef(null);

  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect();
      setOffsetTop(rect.top + window.scrollY);
    }
  }, []);

  const S = useScrollPosition();
  const vh = useViewPortHeight();

  const switchPoint = S - (offsetTop + 300 - vh);

  return (
    <section className="challenges">
      <div className="content_wrap">
        <h3 className="title">Client Overview:</h3>

        <h3 className="detail" ref={sectionRef}>
          {switchPoint < 0 && data?.client_overview}

          {switchPoint >= 0 && (
            <Fragment>
              <span>{data?.client_overview.slice(0, switchPoint)}</span>
              {data?.client_overview.slice(switchPoint)}
            </Fragment>
          )}
        </h3>

        <div className="video_wrap">
          <Parallax
            scale={[0.8, 1.2]}
            translateY={["100px", "-100px"]}
            opacity={[0.7, 1]}
          >
            <img src={data?.img2} alt="Case Study" loading="lazy" />
          </Parallax>
        </div>
      </div>
    </section>
  );
};

export default Challenges;
