import "./ProjectInfo.scss";
import { Fragment, useEffect, useState } from "react";
import useScrollPosition from "../../../utils/hooks/useScrollPosition";
import { useRef } from "react";
import useViewPortHeight from "../../../utils/hooks/useViewPortHeight";

const ProjectInfo = () => {
  const sectionRef = useRef(null);

  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect();
      setOffsetTop(rect.top + window.scrollY);
    }
  }, []);

  const title =
    "End to End custom development solutions tailored for your company";

  const S = useScrollPosition();
  const vh = useViewPortHeight();

  const switchPoint = S - (offsetTop + 300 - vh);

  return (
    <div className="bg_wrap">
      <section className="project_count p_t">
        <div className="content_wrap">
          <div className="text">
            <h5>Over 90+ projects closed</h5>

            <h2 ref={sectionRef}>
              {switchPoint < 0 && title}

              {switchPoint >= 0 && (
                <Fragment>
                  <span>{title.slice(0, switchPoint)}</span>
                  {title.slice(switchPoint)}
                </Fragment>
              )}
            </h2>
          </div>
          <h5>
            Our mission&nbsp;:&nbsp;
            <span>
              To transform your online presence into a captivating and <br />
              functional platform that drives results and enhances user
              experiences.
            </span>
          </h5>
        </div>
      </section>
    </div>
  );
};

export default ProjectInfo;
