import { useLocation } from "react-router-dom";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { paths } from "../../../utils/path";
import "./ServiceLanding.scss";
import { images } from "../../../utils/images";
import { Fragment } from "react";

const ServiceLanding = () => {
  const data = [
    {
      id: paths.webDev,
      bgImg: images.webBg,
      overlay:true,
      mainTitle: "Web Development",
      mainPara1:
        "Boost Your Online Presence with Supagrow's Expert Web Development Services!",
      mainPara2:
        "In the dynamic digital realm, Supagrow emerges as the premier web development force, reshaping industry benchmarks. Our adept team combines skills and cutting-edge tech to fuel unprecedented growth for your business. Committed to the latest trends, we excel in responsive design, optimized performance, and robust functionality.",
    },
    {
      id: paths.uiux,
      bgImg: images.uiuxBg,
      overlay:true,
      mainTitle: "User-Centric UI/UX Design",
      mainPara1: "Boost Your Online Presence with Supagrow's UI/UX Services!",
      mainPara2:
        "Dedicated to the field of UI/UX design, our team is focused on enhancing user experiences through thorough research and creative, user-centered methods. We intricately outline every aspect of the user's journey, ensuring a precise fit with the diverse requirements of our target audience.",
    },
    {
      id: paths.seo,
      bgImg:images.serviceBg,
      overlay:false,
      mainTitle: "Convert Clicks to Customers",
      mainPara1:
        "Boost rankings, attract qualified traffic, and watch your sales and leads soar.",
      mainPara2:
        "Playing a pivotal role in elevating organic visibility at a remarkably lower cost, SEO is a fundamental process for boosting organic sales and increasing website traffic. At Supagrow, we undertake a comprehensive analysis to pinpoint elements that may hinder your website from reaching its full potential.",
    },
    {
      id: paths.mobileApp,
      bgImg: images.appBg,
      overlay:true,
      mainTitle: "Mobile App Development",
      mainPara1:
        "Step into the future of mobile innovation with Supagrow's App Development Services, meticulously crafted using our UI/UX process.",
      mainPara2:
        "Our expert team blends creativity and cutting-edge technology to bring your digital ideas to life with dynamic and user-friendly mobile applications. From concept to deployment, we prioritize seamless functionality and captivating design, ensuring an engaging user experience.",
    },
    {
      id: paths.ecom,
      bgImg:images.serviceBg,
      overlay:false,
      mainTitle: "E-commerce Development",
      mainPara1:
        "Discover E-commerce excellence with Supagrow's streamlined UI/UX-focused Website Development.",
      mainPara2:
        "Elevate your online store with our seamless integration of cutting-edge technology, ensuring visually stunning and user-centric platforms. From initial design concepts to the final interface, we prioritize an engaging user experience at every step.",
    },
    {
      id: paths.lms,
      bgImg:images.serviceBg,
      overlay:false,
      mainTitle: "Custom LMS Development",
      mainPara1:
        "Tired of the limitations posed by your current Learning Management System (LMS)?",
      mainPara2:
        "Supagrow understands the challenges – from outdated features to inflexible interfaces hindering the learning experience. Managing educational content efficiently is critical, and that's where our LMS Development Services step in.",
    },
    {
      id: paths.crm,
      bgImg:images.serviceBg,
      overlay:false,
      mainTitle: "Streamlined CRM Solutions",
      mainPara1:
        "Respond to customer expectations swiftly, or risk losing them in today's rapidly evolving digital landscape.",
      mainPara2:
        "In a dynamic world of marketing and digitally segmented customers, Supagrow empowers you to establish clear connections, redefine customer relationships, and imbue a customer-friendly touch across all facets of your business.",
    },
    {
      id: paths.hrms,
      bgImg:images.serviceBg,
      overlay:false,
      mainTitle: "HRMS Solutions",
      mainPara1:
        "Efficiently manage your human capital with our advanced HRMS solutions, tailored to optimize HR processes and empower your team.",
      mainPara2:
        "At your company, we redefine HR management by combining efficiency with innovation, ensuring your workforce thrives. Discover how our advanced technology transforms HR into a strategic asset, empowering your team to focus on what matters most—achieving your business goals.",
    },
    {
      id: paths.cyberSec,
      bgImg:images.serviceBg,
      overlay:false,
      mainTitle: "Cyber Security Product Development",
      mainPara1:
        "Boost Your Online Presence with Supagrow's Expert Web Development Services!",
      mainPara2:
        "In the dynamic digital realm, Supagrow emerges as the premier web development force, reshaping industry benchmarks. Our adept team combines skills and cutting-edge tech to fuel unprecedented growth for your business. Committed to the latest trends, we excel in responsive design, optimized performance, and robust functionality.",
    },
    {
      id: paths.cms,
      bgImg:images.serviceBg,
      overlay:false,
      mainTitle: "Efficient CMS Solutions",
      mainPara1:
        "Tired of struggling with outdated and inflexible content management systems? Supagrow understands the pain points you face in managing your content efficiently",
      mainPara2:
        "Enter Supagrow's CMS Development Services, your tailored solution to content management challenges. Our expert team addresses these pain points head-on by leveraging cutting-edge technology to build a customized Content Management System (CMS) designed around your specific needs. Say goodbye to rigid interfaces and workflow inefficiencies.",
    },
  ];
  const location = useLocation();

  const target = () => {
    return data.find((obj) => obj.id === location.pathname);
  };

  const goToServic = () => {
    const section = document.getElementById("services");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="web_dev_landing p_b">
      {target()?.bgImg && (
        <Fragment>
          {
            target()?.overlay && <div className="overlay_bg"></div>
          }
          
          <img
            src={target()?.bgImg}
            alt="Bg"
            className="img_bg"
            loading="lazy"
          />
        </Fragment>
      )}

      <div className="content_wrap">
        <div className="title">
          <h1>{target()?.mainTitle}</h1>
          <p className="para_text">{target()?.mainPara1}</p>
          <p className="para_text">{target()?.mainPara2}</p>
          <PrimaryBtn
            varient="dark"
            title="Explore more"
            onClick={goToServic}
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceLanding;
