import React, { useRef, useState, useEffect } from 'react';

import 'intersection-observer';

const ScrollAnimationSection = ({children}) => {
  const sectionRef = useRef(null);
  const [opacity, setOpacity] = useState(0.2);

  useEffect(() => {
    const section = sectionRef.current;

    const handleScroll = () => {
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top >= 0 && rect.bottom <= windowHeight) {
        setOpacity(1);
      } else if (rect.top < windowHeight && rect.bottom > 0) {
        const distanceFromBottom = windowHeight - rect.top;
        const newOpacity = Math.min(1, 0.2 + (distanceFromBottom / 50) * 0.1);
        setOpacity(newOpacity);
      } else {
        setOpacity(0.2);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', handleScroll);
        } else {
          window.removeEventListener('scroll', handleScroll);
        }
      },
      { threshold: [0, 1] }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      style={{
        opacity: opacity,
        transition: 'all 0.6s ease-in-out',        
      }}
    >

    {children}
    </div>
  );
};

export default ScrollAnimationSection;