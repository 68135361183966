import WebLayout from "../../components/webLayout/WebLayout";
import { domain } from "../../utils/apiData";
import CaseStudiesList from "./caseStudiesList/CaseStudiesList";
import { Helmet } from "react-helmet";

const CaseStudies = () => {
  return (
    <WebLayout>
      <Helmet>
        <title>Case Studies | SupaGrow</title>
        <meta name="description" content="Our Case Studies" />
        <link rel="canonical" href={`${domain}/casestudies`} />
      </Helmet>

      <CaseStudiesList />
    </WebLayout>
  );
};
export default CaseStudies;
