import { Fragment, useRef, useState } from "react";
import "./ServicesCaseStudy.scss";
import Slider from "react-slick";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import PortFolioCard from "../../../components/portfolioCard/PortFolioCard";

import { caseStudyList } from "../../../utils/caseStudyList";

const ServicesCaseStudy = () => {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActive(next);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const sliderRef = useRef(null);

  const goNext = () => {
    sliderRef.current.slickNext();
  };

  const goPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goTo = (num) => {
    sliderRef.current?.slickGoTo(num);
  };
  return (
    <section className="portfolio_sec p_t">
      <div className="content_wrap">
        <ScrollAnimationSection>
          <div className="title">
            <h2>Our Projects</h2>
            <p>Over 90 successful projects launched.</p>
          </div>
        </ScrollAnimationSection>

        <div className="portfolio_slider">
          <Slider {...settings} ref={sliderRef}>
            {caseStudyList?.map((data, k) => {
              return (
                <Fragment key={data.id}>
                  <PortFolioCard
                    data={data}
                    classList={active !== k ? "blure" : ""}
                    index={k}
                    active={active}
                    goTo={goTo}
                  />
                </Fragment>
              );
            })}
          </Slider>

          <div className="btn_wrap">
            <div className="left">
              <button type="button" onClick={goPrev}>
                <FaArrowLeft />
              </button>
              <button type="button" onClick={goNext}>
                <FaArrowRight />
              </button>
            </div>
            <PrimaryBtn
              varient="dark"
              title="View All"
              onClick={() => navigate("/casestudies")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesCaseStudy;
