import "./Zero.scss";

const Zero = () => {
  return (
    <div className="zero">
      <div className="line">
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>

      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
      </div>
    </div>
  );
};

export default Zero;
