import { useRef, useState } from "react";
import "./TestimonialCard.scss";
import { FaPlay } from "react-icons/fa6";
import { IoMdPause } from "react-icons/io";
import WistiaVideo from "../wistiaVideo/WistiaVideo";

const TestimonialCard = ({ data, index, active }) => {
  return (
    <div className={`feed_card ${active + 1 === index ? "active" : ""}`}>
      {data.video ? (
        <div className="video_wrap">
          <WistiaVideo vId={data?.vId} />
        </div>
      ) : (
        <p className="feed"> {data?.text}</p>
      )}

      <div className={`profile ${data?.video ? "m-0" : ""}`}>
        <div className="img_wrap">
          <img src={data?.profile} alt={data?.name} loading="lazy" />
        </div>
        <div className="text">
          <p>{data.name}</p>
          <p className="post">{data.post}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
