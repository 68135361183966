import "./PrimaryBtn.scss";
import { IoIosArrowForward } from "react-icons/io";

const PrimaryBtn = ({ title, onClick, varient, ...all }) => {
  return (
    <button className={`primary_btn ${varient}`} onClick={onClick} {...all}>
      <p>{title}</p>

      <div className="circle">
        <IoIosArrowForward />
        <div className="slider">
          <IoIosArrowForward />
        </div>
      </div>
    </button>
  );
};

export default PrimaryBtn;
