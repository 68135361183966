import "./Four.scss";

const Four = () => {
  return (
    <div className="four">
      <div className="line">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>
      <div className="line">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>

      <div className="line">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>

      <div className="line">
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>

      <div className="line">
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>

      <div className="line">
      <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>

      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>

      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>
      <div className="line">
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span className="filled"></span>
      </div>

      <div className="line">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>
      <div className="line">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>

      <div className="line">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span className="filled"></span>
        <span className="filled"></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Four;
