import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import "./HomeCta.scss";
import { useContext, useRef } from "react";
import { motion } from "framer-motion";

import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import { webContext } from "../../../webContext/WebContext";

const HomeCta = ({title}) => {
  const ctaRef = useRef(null);

const {openContact} = useContext(webContext)

  return (
    <section className="home_cta p_b">
      <motion.div
        initial={{ scaleX: 0.9 }}
        whileInView={{ scaleX: 1 }}
        transition={{ duration: 0.4, ease: "easeOut" }}
        className="content_wrap center_p"
        ref={ctaRef}
      >
       
        <ScrollAnimationSection>
          <div className="text">
            <h2>{title}</h2>          
            <PrimaryBtn varient="dark" title="Get in Touch" onClick={openContact} />
          </div>
        </ScrollAnimationSection>
      </motion.div>
    </section>
  );
};

export default HomeCta;
