import "./IconInfoCard.scss";

const IconInfoCard = ({ data, theme, classList, style }) => {
  return (
    <div className={`icon_info_card ${theme} ${classList} `} style={style}>
      {data.icon}
      <h3>{data.title}</h3>
      <p>{data.text}</p>
    </div>
  );
};

export default IconInfoCard;
