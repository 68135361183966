import "./ImgInfo.scss";
import { images } from "../../../utils/images";
import useScrollPosition from "../../../utils/hooks/useScrollPosition";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { Fragment } from "react";
import ScrollText from "../../../components/scrollText/ScrollText";
import useViewPortHeight from "../../../utils/hooks/useViewPortHeight";

const ImgInfo = ({ data }) => {
  const viewportHeight = window.innerHeight;
  const scrollPosition = useScrollPosition();

  const goToMore = () => {
    const section = document.getElementById("details");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const S = useScrollPosition();

  return (
    <section className="img_info p_t p_b">
      <div className="content_wrap">
        <img
          src={data?.caseInfo}
          alt="casestudy"
          style={{
            opacity: `${200 / scrollPosition}`,
            scale: `${1 + scrollPosition * (0.1 / 1000)}`,
          }}
          loading="lazy"
        />
        <div className="content">
          <h3>
            {S < 100 && data?.title_para}
            {S >= 100 && (
              <Fragment>
                <span> {data?.title_para.slice(0, S - 100)}</span>

                {data?.title_para.slice(S - 100)}
              </Fragment>
            )}
          </h3>

          <PrimaryBtn varient="dark" title="Know More" onClick={goToMore} />
        </div>
      </div>
    </section>
  );
};

export default ImgInfo;
