import "./ClientList.scss";
import { motion } from "framer-motion";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { clientsLogo } from "../../../utils/clients";

const ClientList = () => {
  const [ddStatus, setDdStatus] = useState(false);

  const category = [
    {
      id: "all",
      type: "All",
    },
    {
      id: "cyberSec",
      type: "Cyber Security",
    },
    {
      id: "ecom",
      type: "E-Commerce",
    },
    {
      id: "edu",
      type: "Education",
    },
    {
      id: "fintech",
      type: "Fintech",
    },
    {
      id: "pharma",
      type: "Pharma",
    },
    {
      id: "fitness",
      type: "Fitness",
    },
    {
      id: "itcon",
      type: "Consulting",
    },
    {
      id: "reales",
      type: "Real Estate",
    },
    {
      id: "ent",
      type: "Entertainment",
    },
    {
      id: "manufact",
      type: "Manufacturing",
    },
  ];

  const [type, setType] = useState(category[0].id);

  const toggler = (e) => {
    e.stopPropagation();
    setDdStatus((prev) => !prev);
  };

  return (
    <section className="client_list p_t p_b" onClick={() => setDdStatus(false)}>
      <div className="content_wrap">
        <h2>Our Clients</h2>
        <div className="category_dd">
          <div className="type_dd" onClick={(e) => toggler(e)}>
            <p>{category?.find((ele) => ele?.id === type)?.type}</p>
            <span className={`${ddStatus ? "open" : "close"}`}>
              <IoIosArrowDown />
            </span>
            <div className={`dd_list ${ddStatus ? "open" : "close"}`}>
              {category.map((item) => {
                return (
                  <button className={item?.id === type ? "active" : ""} onClick={() => setType(item?.id)} key={item?.id}>
                    {item?.type}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className="client_grid">
          {clientsLogo
            ?.filter((ele) => ele.type.includes(type))
            ?.map((data, j) => {
              return (
                <motion.div
                  initial={{ translateY:24}}
                  whileInView={{ translateY:0}}
                  transition={{
                    duration: 0.4,
                    ease: "easeOut",
                    delay: j % 5 * 0.03,
                  }}
                  viewport={{ once: true }}
                  className="client_card"
                  key={data.id}
                >
                  <div className="logo_wraper">
                    <img src={data.img} alt="Client" loading="lazy" />
                  </div>
                </motion.div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ClientList;
