import { NavLink } from "react-router-dom";
import "./SolutionsDd.scss";
import { IoIosArrowForward } from "react-icons/io";
import { paths } from "../../../utils/path";
import { Fragment } from "react";
import { RiShoppingCartFill } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BiSolidBookContent } from "react-icons/bi";
import { MdManageAccounts } from "react-icons/md";
import { SiCyberdefenders } from "react-icons/si";
import { FaEdit } from "react-icons/fa";

const SolutionsDd = () => {
  const industriesTab = [
    {
      id: 0,
      text: "Ecommerce",
      icon: <RiShoppingCartFill />,
      link: paths.ecom,
    },
    {
      id: 1,
      text: "LMS",
      icon: <FaChalkboardTeacher />,
      link: paths.lms,
    },
    {
      id: 2,
      text: "CRM",
      icon: <BiSolidBookContent />,
      link: paths.crm,
    },
    {
      id: 3,
      text: "HRMS",
      icon: <MdManageAccounts />,
      link: paths.hrms,
    },
    {
      id: 4,
      text: "Cyber Security Product Development",
      icon: <SiCyberdefenders />,
      link: paths.cyberSec,
    },
    {
      id: 5,
      text: "CMS",
      icon: <FaEdit />,
      link: paths.cms,
    },
  ];

  return (
    <div className="solutions_dd_sec">
      <div className="solutions_link_grid">
        <h5>Solutions</h5>
        <div className="link_grid">
          {industriesTab.map((data) => {
            return (
              <Fragment key={data?.id}>
                <NavLink to={data?.link}>
                  <p>
                    {data?.icon} {data?.text}
                  </p>
                  <IoIosArrowForward />
                </NavLink>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SolutionsDd;
