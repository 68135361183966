import { useLocation } from "react-router-dom";
import WebLayout from "../../components/webLayout/WebLayout";
import ServicesLanding from "./serviceLanding/ServicesLanding";
import { Helmet } from "react-helmet";
import { domain } from "../../utils/apiData";

const Services = () => {
  const location = useLocation();
  console.log(location);

  return (
    <WebLayout>
   <Helmet>
        <title>Services | SupaGrow</title>
        <meta name="description" content="Our Services" />
        <link rel="canonical" href={`${domain}/services`} />
      </Helmet>
      <ServicesLanding />
    </WebLayout>
  );
};

export default Services;
