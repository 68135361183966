import WebLayout from "../../components/webLayout/WebLayout";
import BlogLanding from "./blogLanding.jsx/BlogLanding";
import BlogsList from "./blogsList/BlogsList";
import { baseUrl, getBlog } from "../../utils/apiData";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HomeCta from "../home/homeCta/HomeCta";

const Blogs = () => {
  const [loader, setLoader] = useState(false);
  const [blogList, setBlogList] = useState([]);

  const getBlogApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getBlog}`);
      if (res?.data?.success) {
        setBlogList(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getBlogApi();
  }, []);

  return (
    <WebLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
      </Helmet>
      <BlogLanding blogList={blogList}/>
      <BlogsList blogList={blogList} />

      <HomeCta
      title="We deliver our best to make your product better."
      />
    </WebLayout>
  );
};
export default Blogs;
