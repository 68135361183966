import "./Testimionial.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { InView } from "react-intersection-observer";
import { Fragment, useContext, useEffect, useState } from "react";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import { webContext } from "../../../webContext/WebContext";

import { FaListUl } from "react-icons/fa";
import { IoAnalyticsSharp } from "react-icons/io5";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaLaptopCode } from "react-icons/fa";
import { SiTestcafe } from "react-icons/si";
import { MdOutlineRocketLaunch } from "react-icons/md";


const Testimionial = () => {
  const [num, setNum] = useState(0);

  const testimonialData = [
    {
      id: 0,
      img: <FaListUl />,
      title: "Planning",
      text: "Brainstorming of initial structure according to requirements and creating wireframes.",
    },
    {
      id: 1,
      img: <IoAnalyticsSharp />,
      title: "Analysis",
      text: "Doing market research and analysing things which can be a valuable part of the project including customer journey and user experience with detailed competitor analysis and heuristic evaluation.",
    },
    {
      id: 2,
      img: <MdOutlineDesignServices />,
      title: "Design",
      text: "Designing of the finalised structure.",
    },
    {
      id: 3,
      img: <FaLaptopCode />,
      title: "Implementation",
      text: "Development of the project with functionalities and integrations.",
    },
    {
      id: 4,
      img: <SiTestcafe />,
      title: "Testing",
      text: "Testing of developed product by the QA Team.",
    },
    {
      id: 5,
      img: <MdOutlineRocketLaunch />,
      title: "Review & Migration",
      text: "Taking valuable inputs from clients before final migration.Once approved, the project is migrated to the client's server.",
    },
  ];

  const { openContact } = useContext(webContext);

  return (
    <section className="testimonials p_t p_b">
      <div className="content_wrap">
        <div className="grid_wrap">
          <div className="text_wraper">
            <ScrollAnimationSection>
              <h2 className="dark_title">Efficient Workflow:</h2>
              <h2 className="dark_title">From Vision to Reality</h2>
            </ScrollAnimationSection>
            <p>
              Streamlined for efficiency and clarity, we collaborate closely to
              deliver exceptional results on time.
            </p>
            <PrimaryBtn title="Get in Touch" onClick={openContact} />
          </div>

          <div className="cards_list">
            {testimonialData.map((data, j) => {
              return (
                <Fragment key={data.id}>
                  <InView
                    as="div"
                    rootMargin="-50% 0% -50% 0%"
                    onChange={(inView, entry) => setNum(j)}
                  >
                    <div
                      className="testimonial_card"
                      style={{
                        opacity: `${j === num ? "1" : "0.5"}`,
                        transform: `scale(${j === num ? 1 : 0.9})`,
                      }}
                    >
                      {data.img}
                      <h3>{data.title}</h3>
                      <p>{data.text}</p>
                    </div>
                  </InView>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimionial;
