import "./ServicesSec.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { images } from "../../../utils/images";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import CardAnim from "../../../components/cardAnim/CardAnim";
import { paths } from "../../../utils/path";
import { useLocation, useNavigate } from "react-router-dom";
import { domain } from "../../../utils/apiData";
import { Helmet } from "react-helmet";

const ServicesSec = () => {
  const navigate = useNavigate();



  return (
    <section className="services_sec p_t" id="secA">
     

      <div className="content_wrap">
        <ScrollAnimationSection>
          <h2>Our Services</h2>
        </ScrollAnimationSection>

        <CardAnim>
          <div className="bi_grid center_p">
            <div className="left">
              <h3>Crafting Seamless Web Experiences</h3>
              <p>
                Boost Your Online Presence with Supagrow's Expert Web
                Development Services!
              </p>
              <PrimaryBtn
                varient="dark"
                title="Know More"
                onClick={() => navigate(paths.webDev)}
              />
            </div>
            <div className="right">
              <img src={images.webBg} alt="service" loading="lazy" />
            </div>
          </div>
        </CardAnim>

        <div className="bi_blog pb-3">
          <CardAnim>
            <div className="blog_card">
              <img
                src={images.uiuxBg}
                alt="blog"
                loading="lazy"
                className="h"
              />
              <div className="text">
                <h3>Driving Business Growth with our UI/UX Solutions</h3>
                <p>
                  Dedicated to the field of UI/UX design, our team is focused on
                  enhancing user experiences through thorough research and
                  creative, user-centered methods.
                </p>
                <PrimaryBtn
                  varient="dark"
                  title="Know More"
                  onClick={() => navigate(paths.uiux)}
                />
              </div>
            </div>
          </CardAnim>

          <CardAnim>
            <div className="blog_card">
              <img src={images.appBg} alt="blog" loading="lazy" className="h" />
              <div className="text">
                <h3>Transforming Vision in to Apps</h3>
                <p>
                  Step into the future of mobile innovation with Supagrow's App
                  Development Services, meticulously crafted using our UI/UX
                  process.
                </p>
                <PrimaryBtn
                  varient="dark"
                  title="Know More"
                  onClick={() => navigate(paths.mobileApp)}
                />
              </div>
            </div>
          </CardAnim>
        </div>
      </div>
    </section>
  );
};

export default ServicesSec;
