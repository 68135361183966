import { Fragment } from "react";
import "./Details.scss";
import ImageWraper from "../imagesVideos/ImageWraper";

const Details = ({ data }) => {
  return (
    <section className="details p_t" id="details">
      <div className="content_wrap">
        {/* Challenges */}
        <div className="bi_grid">
          <h3 className="left">Challenges</h3>
          <div className="right">
            {data?.challenges?.map((item) => {
              return (
                <Fragment key={item?.id}>
                  <h3 className="detail">
                    <b>{item?.title}</b> {item?.para}
                  </h3>
                </Fragment>
              );
            })}
          </div>
        </div>

        {/* Approach */}
        <div className="bi_grid">
          <h3 className="left">Our Approach:</h3>
          <div className="right">
            {data?.approach?.map((item) => {
              return (
                <Fragment key={item?.id}>
                <h3 className="detail">
                    <b>{item?.title}</b>
                  </h3>
                  <h3 className="detail">{item?.para}</h3>
                </Fragment>
              );
            })}
          </div>
        </div>

       {/* Results */}
        <div className="bi_grid">
          <h3 className="left">Results:</h3>
          <div className="right">
            {data?.result?.map((item) => {
              return (
                <Fragment key={item?.id}>
                 <h3 className="detail">
                    <b>{item?.title}</b>
                  </h3>
                  <h3 className="detail">{item?.para}</h3>
                </Fragment>
              );
            })}
          </div>
        </div>

        {/* Conclusion */}
        <div className="bi_grid">
          <h3 className="left">Conclusion:</h3>
          <div className="right">
          <h3 className="detail">{data?.Conclusion || "-"}</h3>
          </div>
        </div>

        <div className="tri_info p_b">
          <div className="project_info">
            <p className="p_second">COMPANY</p>
            <p>{data?.company}</p>
          </div>
          <div className="project_info">
            <p className="p_second">INDUSTRY</p>
            <p>{data?.industry}</p>
          </div>
          <div className="project_info">
            <p className="p_second">LOCATION</p>
            <p>{data?.location}</p>
          </div>
          <div className="project_info">
            <p className="p_second">WEBSITE</p>
            <a href={data?.website} target="_blank">
              <p>{data?.website}</p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Details;
