import { Fragment } from "react";
import "./ServiceOfferings.scss";
import OfferingPill from "./offeringPill/OfferingPill";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";

const ServiceOfferings = () => {
  const offeringData = [
    {
      id: "01",
      title: "Experienced team",
      text:" Highly skilled team proficient in diverse technologies, delivering expertise tailored to your needs."
    },
    {
      id: "02",
      title:"Structured development",
      text:"A structured development plan with clear milestones to ensure timely progress and alignment with project goals."
    },
    {
      id: "03",
      title: "Responsive to project needs",
      text:"Adaptable and responsive to evolving project requirements, ensuring seamless adjustments and optimal outcomes."
    },
    {
      id: "04",
      title: "Innovate your brand.",
      text:" Elevate your brand with innovative experiences that captivate and engage your audience, setting you apart in your industry."
    },
    {
      id:"05",
      title:"Strategy driven execution",
      text:" Execute with strategy at the core, ensuring every action aligns with your long-term goals and drives impactful results."
    }
  ];

  return (
    <section className="services_offerings">
      <div className="content_wrap p_t">
        <ScrollAnimationSection>
          <h2 className="text-center">Why Supagrow?</h2>
        </ScrollAnimationSection>

        {offeringData.map((data) => {
          return (
            <Fragment key={data.id}>
              <OfferingPill data={data} />
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default ServiceOfferings;
