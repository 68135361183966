import { Fragment, useState } from "react";
import "./BlogsList.scss";
import { images } from "../../../utils/images";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BlogCard from "./blogCard/BlogCard";

const BlogsList = ({ blogList }) => {

  const category = ["Resources", "Comarision", "Guides", "AI", "Tops"];
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const selecteCategory = (item) => {
    setSelected((prev) => {
      if (prev.includes(item)) {
        return prev.filter((ele) => ele !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  return (
    <section className="blog_list_sec p_t p_b">
      <div className="content_wrap">
        <div className="filter_wrap">
          <h2 className="dark_title">Blogs</h2>
          {/* <div className="search_wrap">
            <input type="text" placeholder="Search any post" />
            <IoSearchOutline />
          </div>
          <p>Select Topic:</p>
          <div className="pills_list">
            {category.map((item, l) => {
              return (
                <button
                  type="button"
                  className={selected.includes(item) ? "active" : ""}
                  onClick={() => selecteCategory(item)}
                  key={l}
                >
                  <p>{item}</p>
                </button>
              );
            })}
          </div> */}
        </div>
        <div className="blogs_grid">
          {blogList?.map((data) => {
            return (
              <Fragment key={data?._id}>
                <BlogCard data={data} />
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogsList;
