import "./ServiceBenefits.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { Fragment, useRef, useState } from "react";
import Slider from "react-slick";

import { GoGlobe } from "react-icons/go";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { SiGooglebigquery } from "react-icons/si";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import IconInfoCard from "../../../components/iconInfoCard/IconInfoCard";
import { useNavigate } from "react-router-dom";

const ServiceBenefits = () => {
  const sliderData = [
    {
      id: 0,
      icon: <GoGlobe />,
      title: "Crafting Seamless Web Experiences",
      text: "Boost Your Online Presence with Supagrow's Expert Web Development Services!",
    },
    {
      id: 1,
      icon: <MdOutlineDesignServices />,
      title: "Driving Business Growth with our UI/UX Solutions",
      text: "Dedicated to the field of UI/UX design, our team is focused on enhancing user experiences through thorough research and creative, user-centered methods.",
    },
    {
      id: 2,
      icon: <FaMobileAlt />,
      title: "Transforming Vision in to Apps",
      text: "Step into the future of mobile innovation with Supagrow's App Development Services, meticulously crafted using our UI/UX process.",
    },
    {
      id: 3,
      icon: <SiGooglebigquery />,
      title: "Turning Clicks into Customers",
      text: "Boost rankings, attract qualified traffic, and watch your sales and leads soar.",
    },
  ];

  const slideRef = useRef();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActive(next);
    },
  };

  const switchTo = (num) => {
    slideRef.current?.slickGoTo(num);
    setActive(num);
  };

  return (
    <Fragment>
      <section className="service_benefit_title">
        <div className="content_wrap p_t">
          <ScrollAnimationSection>
            <div className="title">
              <h2>
                Transforming <br /> <span>Digital Ventures</span>
              </h2>
              <PrimaryBtn
                varient="dark"
                title="View Services"
                onClick={() => navigate("/services")}
              />
            </div>
          </ScrollAnimationSection>
        </div>
      </section>

      <section className="service_benefit_slider">
        <div className="horizntal_slider">
          <div className="sliders_tab_wrap p_top">
            <div className="content_wrap">
              <button
                type="button"
                className={active === 0 ? "active" : ""}
                onClick={() => switchTo(0)}
              >
                Websites
              </button>
              <button
                type="button"
                className={active === 1 ? "active" : ""}
                onClick={() => switchTo(1)}
              >
                UI/UX
              </button>
              <button
                type="button"
                className={active === 2 ? "active" : ""}
                onClick={() => switchTo(2)}
              >
                Mobile Apps
              </button>
              <button
                type="button"
                className={active === 3 ? "active" : ""}
                onClick={() => switchTo(3)}
              >
                SEO
              </button>
            </div>
          </div>

          <div className="tab_slider p_top">
            <Slider ref={slideRef} {...settings}>
              {sliderData.map((data) => {
                return (
                  <Fragment key={data.id}>
                    <IconInfoCard
                      theme="dark"
                      data={data}
                      classList={data.id === active ? "active" : ""}
                    />
                  </Fragment>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>

      {/* For Mobile */}
      <section className="mobile_sec p_top">
        <div className="content_wrap">
          {sliderData.map((data, k) => {
            return (
              <Fragment key={data.id}>
                <IconInfoCard
                  theme="dark"
                  data={data}
                  classList={data.id === active ? "active" : ""}
                  style={{ position: "sticky", top: `${80 + k * 20}px` }}
                />
              </Fragment>
            );
          })}
        </div>
      </section>
    </Fragment>
  );
};

export default ServiceBenefits;
