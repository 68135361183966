import { Fragment, useState } from "react";
import HomeLanding from "./homeLanding/HomeLanding";
import WebLayout from "../../components/webLayout/WebLayout";
import ProjectInfo from "./projectInfo/ProjectInfo";
import Portfolio from "./portfolio/Portfolio";
import Testimionial from "./testimonial/Testimionial";
import HomeCta from "./homeCta/HomeCta";
import FeedBackSlide from "./feedBackSlide/FeedBackSlide";
import Healping from "./healping/Healping";
import HomeBlogSec from "./homeBlogSec/HomeBlogSec";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { domain } from "../../utils/apiData";

const Home = () => {
  return (
    <WebLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software, Web Development, and SEO Services | Supagrow</title>
        <meta
          name="description"
          content="Supagrow specializes in expert software and website development services, tailored to optimize your digital presence and business operations."
        />
        {/* Canonical */}
        <link rel="canonical" href={domain} />
      </Helmet>
      <HomeLanding />
      <ProjectInfo />
      <Portfolio />
      <Healping />
      <FeedBackSlide />
      <Testimionial />
      <HomeBlogSec /> 
      <HomeCta title="We deliver our best to make your product better." />
    </WebLayout>
  );
};

export default Home;
