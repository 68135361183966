import { images } from "../../utils/images";
import { videos } from "../../utils/videos";
import "./MainLoader.scss";

const MainLoader = () => {
  return (
    <section className="loader_sec">
      <div className="content_wrap">
       
        <img
          src={images.logoWhite}
          className="brand_logo"
          alt="Brand Logo Of Supagrow"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default MainLoader;
