import { Fragment, createRef, useEffect, useState } from "react";
import "./MidTab.scss";
import ServicesSec from "../serviesSec/ServicesSec";
import Portfolio from "../../home/portfolio/Portfolio";
import StrategiesSec from "../strategiesSec/StrategiesSec";
import ReviewSlide from "../reviewSlide/ReviewSlide";

import CardStackSec from "../cardStackSec/CardStackSec";
import ScrollSpy from "react-scrollspy-navigation";
import AboutCaseStudy from "../aboutCaseStudy/AboutCaseStudy";
import AboutProcess from "../aboutProcess/AboutProcess";
import Team from "../team/Team";
import MultiGridInfo from "../multiGridInfo/MultiGridInfo";
import MapLocationInfo from "../mapLocationInfo/MapLocationInfo";

const MidTab = ({extraH}) => {
  const goTo = (secId) => {
    const section = document.getElementById(secId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop + extraH + 400,
        behavior: "smooth",
      });
    }
  };

  return (
    <Fragment>
      <div style={{ position: "relative", zIndex: "4" }}>
        <section className="mid_tab">
          <div className="content_wrap">
            <div className="md_tab_line">
              <ScrollSpy>
                <div className="tab_wraper_line">
                  <div className="btn_wrap">
                    <button type="button" onClick={() => goTo("secA")}>
                      Services
                    </button>
                    <button type="button" onClick={() => goTo("secB")}>
                      Projects
                    </button>
                    <button type="button" onClick={() => goTo("secC")}>
                      Process
                    </button>
                    <button type="button" onClick={() => goTo("secD")}>
                      Review
                    </button>
                    <button type="button" onClick={() => goTo("secE")}>
                      Quote
                    </button>
                    <button type="button" onClick={() => goTo("secF")}>
                      Team
                    </button>
                    <button type="button" onClick={() => goTo("secG")}>
                      Presence
                    </button>
                  </div>
                  <a href="#secA" ref={createRef()}>
                    Services
                  </a>
                  <a href="#secB" ref={createRef()}>
                    Projects
                  </a>
                  <a href="#secC" ref={createRef()}>
                    Process
                  </a>
                  <a href="#secD" ref={createRef()}>
                    Review
                  </a>
                  <a href="#secE" ref={createRef()}>
                    Quote
                  </a>
                  <a href="#secF" ref={createRef()}>
                    Team
                  </a>
                  <a href="#secG" ref={createRef()}>
                    Presence
                  </a>
                </div>
              </ScrollSpy>
            </div>
          </div>
        </section>

   
          <ServicesSec />
     

  
          <AboutCaseStudy />
  

          <StrategiesSec />


          <ReviewSlide />
        

          <AboutProcess />

      
          <Team />
      

        <MultiGridInfo />


          <MapLocationInfo />

      </div>
    </Fragment>
  );
};

export default MidTab;
