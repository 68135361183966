import WebLayout from "../../components/webLayout/WebLayout";
import useScrollPosition from "../../utils/hooks/useScrollPosition";
import CaseDetailLanding from "./caseDetailLandiung/CaseDetailLanding";
import Challenges from "./challenges/Challenges";
import Details from "./details/Details";
import ImgInfo from "./imgInfo/ImgInfo";
import HomeCta from "../home/homeCta/HomeCta";
import { useLocation, useParams } from "react-router-dom";
import { caseStudiesData } from "../../utils/caseStudyData";
import useViewPortHeight from "../../utils/hooks/useViewPortHeight";
import { domain } from "../../utils/apiData";
import { Helmet } from "react-helmet";

const CaseStudyDetail = () => {
  const { id } = useParams();

  const data = caseStudiesData.find((item) => item?.id === id);

  const location = useLocation();

  return (
    <WebLayout>
      <Helmet>
        <title>Case Study | SupaGrow</title>
        <meta name="description" content="Our Case Studies" />
        <link rel="canonical" href={`${domain}${location?.pathname}`} />
      </Helmet>
      <CaseDetailLanding data={data} />
      <ImgInfo data={data} />
      <Challenges data={data} />
      <Details data={data} />
      <HomeCta title="We deliver our best to make your product better." />
    </WebLayout>
  );
};

export default CaseStudyDetail;
