import "./WebSidebar.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoClose } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import { webContext } from "../../webContext/WebContext";
import { Fragment, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { IoIosArrowDown } from "react-icons/io";

// Utils
import { paths } from "../../utils/path";
import { images } from "../../utils/images";

// Social Icons
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaBehance } from "react-icons/fa";

const WebSidebar = ({ sidebarStatus, closeSidebar }) => {
  const { openContact } = useContext(webContext);
  const location = useLocation();

  const companyTabs = [
    {
      id: 0,
      text: "Home",
      link: paths.home,
    },
    {
      id: 1,
      text: "About",
      link: paths.about,
    },
    {
      id: 2,
      text: "Clients",
      link: paths.clients,
    },
    {
      id: 3,
      text: "Case Studies",
      link: paths.caseStudies,
    },
    {
      id: 4,
      text: "Blogs",
      link: paths.blogs,
    },
  ];

  const servicesTabs = [
    {
      id: 0,
      text: "Web Development",
      link: paths.webDev,
    },
    {
      id: 1,
      text: "UI/UX",
      link: paths.uiux,
    },
    {
      id: 2,
      text: "SEO",
      link: paths.seo,
    },
    {
      id: 3,
      text: "Mobile App Development",
      link: paths.mobileApp,
    },
  ];

  const solutionsTabs = [
    {
      id: 0,
      text: "Ecommerce",
      link: paths.ecom,
    },
    {
      id: 1,
      text: "LMS",
      link: paths.lms,
    },
    {
      id: 2,
      text: "CRM",
      link: paths.crm,
    },
    {
      id: 3,
      text: "HRMS",
      link: paths.hrms,
    },
    {
      id: 4,
      text: "Cyber Security Product Development",
      link: paths.cyberSec,
    },
    {
      id: 5,
      text: "CMS",
      link: paths.cms,
    },
  ];

  const servicesPaths = [paths.webDev, paths.uiux, paths.seo, paths.mobileApp];
  const solutionsPaths = [
    paths.ecom,
    paths.lms,
    paths.crm,
    paths.hrms,
    paths.cyberSec,
    paths.cms,
  ];
  return (
    <Offcanvas
      show={sidebarStatus}
      onHide={closeSidebar}
      className="menu_sider"
      placement="top"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          <img src={images.logo} alt="Brand Logo" loading="lazy" />
          <button type="button" onClick={closeSidebar}>
            <IoClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {companyTabs.map((data) => {
          return (
            <Fragment key={data.id}>
              <NavLink to={data.link} onClick={closeSidebar}>
                {data.text}
              </NavLink>
            </Fragment>
          );
        })}
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              className={
                servicesPaths.includes(location?.pathname) ? "dark" : ""
              }
            >
              Services <IoIosArrowDown />
            </Accordion.Header>
            <Accordion.Body>
              <NavLink to="/services" end onClick={closeSidebar}>
                Services
              </NavLink>
              {servicesTabs.map((data) => {
                return (
                  <Fragment key={data?.id}>
                    <NavLink to={data.link} onClick={closeSidebar}>
                      {data.text}
                    </NavLink>
                  </Fragment>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header
              className={
                solutionsPaths.includes(location?.pathname) ? "dark" : ""
              }
            >
              Solutions <IoIosArrowDown />
            </Accordion.Header>
            <Accordion.Body>
              {solutionsTabs.map((data) => {
                return (
                  <Fragment key={data?.id}>
                    <NavLink to={data.link} onClick={closeSidebar}>
                      {data.text}
                    </NavLink>
                  </Fragment>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <button
          type="button"
          className="contact"
          onClick={() => {
            openContact();
            closeSidebar();
          }}
        >
          Contact
        </button>

        <div className="social_connect">
          <a href="https://in.linkedin.com/company/supagrow" target="_blank">
            <FaLinkedinIn />
          </a>

          <a href="https://www.instagram.com/supagrow/" target="_blank">
            <FaInstagram />
          </a>
          <a href="https://www.behance.net/supagrow" target="_blank">
            <FaBehance />
          </a>
          <a href="https://twitter.com/supagrow_" target="_blank">
            <FaXTwitter />
          </a>

          <a href="https://www.facebook.com/supagrow01/" target="_blank">
            <FaFacebookF />
          </a>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default WebSidebar;
