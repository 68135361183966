import "./WistiaVideo.scss";
import React, { useEffect } from "react";

const WistiaVideo = ({ vId }) => {
  useEffect(() => {
    // Create script elements
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${vId}.jsonp`;
    script1.async = true;

    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    // Append scripts to the document body
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    // Clean up scripts when the component is unmounted
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: "56.25% 0 0 0", position: "relative" }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "400px",
          left: "0",
          position: "absolute",
          top: "0",
          width: "100%",
          objectFit: "contain",
        }}
      >
        <div
          className={`wistia_embed wistia_async_${vId} seo=true videoFoam=true`}
          style={{ height: "100%", position: "relative", width: "100%" }}
        >
          <div
            className="wistia_swatch"
            style={{
              height: "100%",
              left: "0",
              opacity: "0",
              overflow: "hidden",
              position: "absolute",
              top: "0",
              transition: "opacity 200ms",
              width: "100%",
            }}
          >
            <img
              src={`https://fast.wistia.com/embed/medias/${vId}/swatch`}
              style={{
                filter: "blur(5px)",
                height: "100%",
                objectFit: "contain",
                width: "100%",
              }}
              alt="Testimonials Provider"
              aria-hidden="true"
              // onLoad={() => (this.parentNode.style.opacity = 1)}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WistiaVideo;
