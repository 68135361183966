import { images } from "./images";

export const clientsLogo = [
  {
    id: 0,
    img: images.aglow,
    type: ["ecom", "all"],
  },
  {
    id: 1,
    img: images.cybergain,
    type: ["cyberSec", "edu", "all"],
  },
  {
    id: 2,
    img: images.cycops,
    type: ["cyberSec", "all"],
  },
  {
    id: 3,
    img: images.dwaraka,
    type: ["ecom", "all"],
  },
  {
    id: 4,
    img: images.finneva,
    type: ["fintech", "all"],
  },
  {
    id: 5,
    img: images.gate8,
    type: ["ecom", "all"],
  },
  {
    id: 6,
    img: images.hunter,
    type: ["cyberSec", "all"],
  },
  {
    id: 7,
    img: images.inauditus,
    type: ["edu", "all"],
  },
  {
    id: 8,
    img: images.keyston,
    type: ["itcon", "all"],
  },
  {
    id: 9,
    img: images.livinout,
    type: ["reales", "all"],
  },
  {
    id: 10,
    img: images.secops,
    type: ["cyberSec", "edu", "all"],
  },
  {
    id: 11,
    img: images.sharepass,
    type: ["cyberSec", "all"],
  },
  {
    id: 12,
    img: images.tacticons,
    type: ["itcon", "all"],
  },
  {
    id: 13,
    img: images.techdefence,
    type: ["cyberSec", "edu", "all"],
  },
  {
    id: 14,
    img: images.worldinformatix,
    type: ["cyberSec", "all"],
  },
  {
    id: 15,
    img: images.cybervie,
    type: ["cyberSec","edu" ,"all"],
  },
  {
    id: 16,
    img: images.farsight,
    type: ["itcon", "cyberSec", "all"],
  },
  {
    id: 17,
    img: images.ionots,
    type: ["cyberSec", "all"],
  },
  {
    id: 18,
    img: images.phytives,
    type: ["ecom","pharma","all"],
  },
  {
    id: 19,
    img: images.sec4cyber,
    type: ["cyberSec", "all"],
  },
  {
    id: 20,
    img: images.slimbeez,
    type: ["fitness","all"],
  },
  {
    id: 21,
    img: images.tfg,
    type: ["fitness", "all"],
  },
  {
    id: 22,
    img: images.theVoiceBank,
    type: ["ent", "all"],
  },
  {
    id: 23,
    img: images.vulnMachine,
    type: ["cyberSec", "edu", "all"],
  },
  {
    id: 24,
    img: images.masteryInside,
    type: ["itcon", "all"],
  },
  {
    id: 25,
    img: images.sogo,
    type: ["manufact","itcon","all"],
  },
  {
    id: 26,
    img: images.symbio,
    type: ["pharma", "all"],
  },
  {
    id: 27,
    img: images.firstbit,
    type: ["edu", "all"],
  },
];
