import "./ServiceClients.scss";
import Slider from "react-slick";
import { images } from "../../../utils/images";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { useNavigate } from "react-router-dom";

import { clientsLogo } from "../../../utils/clients";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";

const ServiceClients = () => {
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="service_client">
      <div className="content_wrap p_t">
        <ScrollAnimationSection>
          <div className="title">
            <h2 className="text-center">Our Clients</h2>

            <PrimaryBtn
              varient="dark"
              title="View More"
              onClick={() => navigate("/clients")}
            />
          </div>
        </ScrollAnimationSection>
        <Slider {...settings}>
          {clientsLogo?.map((data, j) => {
            return (
              <div className="client_card_wrap" key={data.id}>
                <div className="logo_wraper">
                  <img src={data.img} alt="Client" loading="lazy" />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default ServiceClients;
