import WebLayout from "../../components/webLayout/WebLayout";
import ServiceMidTab from "./serviceMidTab/ServiceMidTab";
import ServiceLanding from "./serviceLanding/ServiceLanding";
import HomeCta from "../../pages/home/homeCta/HomeCta";
import { paths } from "../../utils/path";
import "../../../node_modules/bootstrap/js/src/scrollspy";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { domain } from "../../utils/apiData";

const ServiceDetail = () => {
  const data = [
    {
      id: paths.webDev,
      mainTitle: "Best Custom Website Development Services in India | Supagrow",
      description:
        "Custom Website Development Services for Ecommerce, Cyber Security, Real Estate and much more, get Laravel, Php website etc built for your business today with Supagrow, Visit Now!",
    },
    {
      id: paths.uiux,
      mainTitle: "UI/UX Design Company | UI/UX Development Services | Supagrow",
      description:
        "Explore our UI/UX design services for seamless user experiences. We're a trusted company dedicated to crafting simple and effective solutions for your digital needs.",
    },
    {
      id: paths.seo,
      mainTitle: "Best On-Page and Off-Page SEO services in India | SupaGrow",
      description:
        "Boost your website's visibility and rankings with our top-notch on-page and off-page SEO services. Get in touch with SupaGrow today for expert guidance and guaranteed results.",
    },
    {
      id: paths.mobileApp,
      mainTitle:
        "Custom Mobile App Development Services In India | Android App Development",
      description:
        "Get tailored mobile app development services in India. We specialize in Android app development, creating solutions that cater to your unique needs.",
    },
    {
      id: paths.ecom,
      mainTitle:
        "E-Commerce Website And Software Development In India | SupaGrow",
      description:
        "Empower your business with SupaGrow's E-commerce website and software development solutions in India. Elevate your online presence and drive growth.",
    },
    {
      id: paths.lms,
      mainTitle:
        "Educational Software and Website Development |LMS Development| SupaGrow",
      description:
        "Empower education through SupaGrow's software and website development. Transform learning experiences with tailored solutions for educational institutions.",
    },
    {
      id: paths.crm,
      mainTitle: "CRM Software Development Company In India | SupaGrow",
      description:
        "Your Trusted CRM Software Development Partner in India. Unlock Enhanced Customer Relationships and Efficiency with Our Custom Solutions. Visit now!",
    },
    {
      id: paths.hrms,
      overlay: false,
      mainTitle: "Best Software Development Solutions for HR | SupaGrow",
      description:
        "Discover top-notch HR software development solutions by SupaGrow. Optimize your human resources processes with our customized software for enhanced efficiency.",
    },
    {
      id: paths.cyberSec,
      mainTitle: "Cyber Security Web Development And designing | SupaGrow",
      description:
        "Secure your digital landscape with SupaGrow's cyber security web development and designing. Protect your data and users with cutting-edge solutions.",
    },
    {
      id: paths.cms,
      mainTitle: "Custom CMS Development | CMS Development Services | SupaGrow",
      description:
        "Experience efficient content management with SupaGrow's custom CMS development services. Streamline your online presence for better engagement and control.",
    },
  ];

  const location = useLocation();

  const target = () => {
    return data.find((obj) => obj.id === location.pathname);
  };

  return (
    <WebLayout>
      <Helmet>
        <title>{target()?.mainTitle}</title>
        <meta name="description" content={target()?.description} />
        <link rel="canonical" href={`${domain}${location?.pathname}`} />
      </Helmet>

      <ServiceLanding />
      <ServiceMidTab />

      <HomeCta title="We deliver our best to make your product better." />
    </WebLayout>
  );
};

export default ServiceDetail;
