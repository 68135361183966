import { Fragment } from "react";
import "./PortFolioCard.scss";
import ArrowBtn from "../arrowBtn/ArrowBtn";
import { useNavigate } from "react-router-dom";

const PortFolioCard = ({data, classList, goTo, index, active}) => {

const navigate = useNavigate();

    return(
        <div
        className={`portfolio_card ${classList}`}
        onClick={() => goTo(active !== index ? index : active)}
      >
        <div className="img_screen">
         <img src={data?.img} alt={data?.id} loading="lazy" />
        </div>
        <div className="name_wrap">
          <div className="left">
          <h3>{data?.shortTitle}</h3>
          <p className="p_second">{data?.name}</p>
          </div>
          <ArrowBtn onClick={() => navigate(`/casestudies/${data?.id}`)} />
        </div>
      </div>
    )
}

export default PortFolioCard;