import { Fragment, useState } from "react";
import "./CaseStudiesList.scss";
import { images } from "../../../utils/images";
import { useNavigate } from "react-router-dom";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import { caseStudyList } from "../../../utils/caseStudyList";

const CaseStudiesList = () => {
  const navigate = useNavigate();
  const types = [
    "All",
    "Ecommerce",
    "Education",
    "Security",
    "Construction",
    "Management",
  ];

  const [selected, setSelected] = useState(types[0]);

  return (
    <section className="case_study_list p_t p_b">
      <div className="content_wrap">
        <h2 className="dark_title">Our Work in Action</h2>
        <p className="title_para">
          Explore our work in action and discover how we bring ideas to life
          with real-world impact.
        </p>

        <div className="case_studies_cards">
          {caseStudyList?.map((data) => {
            return (
              <Fragment key={data.id}>
                <ScrollAnimationSection>
                  <div
                    className="case_card"
                    onClick={() => navigate(`/casestudies/${data.id}`)}
                  >
                    <div className="text">
                      <div className="tag_wrap">
                        {data.tags.map((item, j) => {
                          return (
                            <p key={j}>
                              {item}{" "}
                              <span
                                className={
                                  j > data.tags.length - 2 ? "none" : ""
                                }
                              ></span>
                            </p>
                          );
                        })}
                      </div>
                      <h3>{data.title}</h3>
                      <p className="dark">{data.name}</p>
                      <p className="p_second">{data.date}</p>
                    </div>
                    <div className="img_wrap">
                      <img src={data.img} alt="Case Study" loading="lazy" />
                    </div>
                  </div>
                </ScrollAnimationSection>
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CaseStudiesList;
