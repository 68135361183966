export const paths = {

  // Services Links
  webDev: "/services/custom-website-development",
  uiux: "/services/uiux-design",
  seo: "/services/search-engine-optmization",
  mobileApp: "/services/mobile-application-development",

  // Industries Links
  ecom: "/industry/ecommerce-website-and-app-development",
  lms: "/industry/educational-software-and-web-development",
  crm: "/industry/crm-software-development",
  hrms: "/industry/hr-software-development",
  cyberSec: "/industry/cyber-security-website-development",
  cms: "/industry/custom-cms-dvelopment",

  // Other Links
  home:"/",
  about:"/about",
  services:"/services",
  clients:"/clients",
  caseStudies:"/casestudies",
  blogs:"/blogs",
};
