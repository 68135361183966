// Domain for meta use
export const domain = "https://supagrow.in";

// baseUrl for API use
export const baseUrl = "https://backendsupagrow.supagrow.in";

export const getBlog = "api/getblogs";
export const blogDetail = "api/blogdetails";
export const contact = "api/storecontactapi";
export const emailSubscribe = "api/storenewsapi";
