import "./MapLocationInfo.scss";
import { images } from "../../../utils/images";

const MapLocationInfo = () => {
  const locations = [
    {
      id: "a",
      country: "India",
      code: "IND",
      flag: images.india,
    },
    {
      id: "b",
      country: "Australia",
      code: "AUS",
      flag: images.australia,
    },
    {
      id: "c",
      country: "Ireland",
      code: "IRL",
      flag: images.ireland,
    },
    {
      id: "d",
      country: "Unites states",
      code: "USA",
      flag: images.usa,
    },
    {
      id: "e",
      country: "Dubai",
      code: "UAE",
      flag: images.uae,
    },
    {
      id: "f",
      country: "Israel",
      code: "ISR",
      flag: images.israel,
    },
    {
      id: "g",
      country: "United Kingdom",
      code: "UK",
      flag: images.uk,
    },
  ];

  return (
    <section className="map_image_info p_t p_b" id="secG">
      <div className="content_wrap">
        <div className="map_wraper">
          <img src={images.globalMap} alt="map" loading="lazy" />
          {locations?.map((data) => {
            return (
              <button type="button" className={data.id} key={data?.id}>
                <div className="flag_wrap">
                  <img src={data?.flag} alt="Flag" />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default MapLocationInfo;
