import "./AboutProcess.scss";

import { MdOutlineOnlinePrediction } from "react-icons/md";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import { MdOutlineArrowForward } from "react-icons/md";
import { useContext } from "react";
import { webContext } from "../../../webContext/WebContext";


import { MdWifiCalling3 } from "react-icons/md";
import { RiMailCheckFill } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";


const AboutProcess = () => {
  const { openContact } = useContext(webContext);

  return (
    <section className="about_process p_t" id="secE">
      <div className="content_wrap">
        <div className="title">
          <ScrollAnimationSection>
            <h2 className="text-center">Request a free quote</h2>
          </ScrollAnimationSection>
        </div>
        <div className="process_grid p_top">
          <div className="process_card active">
            <p className="go_contact ps-4" onClick={openContact}>
              01. Get in touch{" "}
              <span>
                <MdOutlineArrowForward />
              </span>
            </p>
            <div className="curve_box">
              <div className="icon_wrap">
                <MdWifiCalling3 />
              </div>
            </div>
          </div>

          <div className="process_card">
            <p className="ps-4">02. Connect with us virtually</p>
            <div className="curve_box">
              <div className="icon_wrap">
                <MdOutlineOnlinePrediction />
              </div>
            </div>
          </div>

          <div className="process_card">
            <p className="ps-4">03. Get estimation</p>
            <div className="curve_box">
              <div className="icon_wrap">
                <RiMailCheckFill />
              </div>
            </div>
          </div>

          <div className="process_card">
            <p className="ps-4">04. Work together</p>
            <div className="curve_box">
              <div className="icon_wrap">
                <IoIosPeople />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutProcess;
