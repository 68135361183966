import CardAnim from "../../../components/cardAnim/CardAnim";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import "./MultiGridInfo.scss";
import { HiCubeTransparent } from "react-icons/hi";
import { GiTeamIdea } from "react-icons/gi";
import { PiTreeStructureFill } from "react-icons/pi";
import { MdOutlineEdgesensorHigh } from "react-icons/md";
import { TbBrandAirtable } from "react-icons/tb";
import { PiStrategyBold } from "react-icons/pi";


const MultiGridInfo = () => {
  return (
    <section className="multi_grid_info p_t p_b">
      <div className="content_wrap">
        <ScrollAnimationSection>
          <h2 className="title">Why Choose us?</h2>
        </ScrollAnimationSection>
        <div className="tri_grid_wrap">
          <div className="tri_card">
            <GiTeamIdea />
            <div className="text">
              <h4>Experienced team skilled in diverse technologies.</h4>
              <p>
                Highly skilled team proficient in diverse technologies,
                delivering expertise tailored to your needs.
              </p>
            </div>
          </div>

          <CardAnim>
            <div className="tri_card">
              <PiTreeStructureFill />
              <div className="text">
                <h4>Structured development plan with milestones</h4>
                <p>
                  A structured development plan with clear milestones to ensure
                  timely progress and alignment with project goals.
                </p>
              </div>
            </div>
          </CardAnim>

          <CardAnim>
            <div className="tri_card">
              <MdOutlineEdgesensorHigh />
              <div className="text">
                <h4>Responsive to project needs</h4>
                <p>
                  Adaptable and responsive to evolving project requirements,
                  ensuring seamless adjustments and optimal outcomes.
                </p>
              </div>
            </div>
          </CardAnim>

          <CardAnim>
            <div className="tri_card">
              <TbBrandAirtable />
              <div className="text">
                <h4>Innovate your brand.</h4>
                <p>
                  Elevate your brand with innovative experiences that captivate
                  and engage your audience, setting you apart in your industry.
                </p>
              </div>
            </div>
          </CardAnim>

          <CardAnim>
            <div className="tri_card">
              <PiStrategyBold />
              <div className="text">
                <h4>Strategy driven execution</h4>
                <p>
                  Execute with strategy at the core, ensuring every action
                  aligns with your long-term goals and drives impactful results.
                </p>
              </div>
            </div>
          </CardAnim>
        </div>
        <ScrollAnimationSection>
          <h3>
            Count on Supagrow to spark your business's digital journey, where
            every line of code leads to unparalleled online success.
          </h3>
        </ScrollAnimationSection>
      </div>
    </section>
  );
};

export default MultiGridInfo;
