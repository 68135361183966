import WebLayout from "../../components/webLayout/WebLayout";
import BlogContent from "./blogContent/BlogContent";
import BlogDetailLanding from "./blogDetailLanding/BlogDetailLanding";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl, blogDetail } from "../../utils/apiData";
import { useEffect, useState } from "react";
import HomeCta from "../home/homeCta/HomeCta";

const BlogDetails = () => {
  const { slug } = useParams();
  const [loader, setLoader] = useState(false);
  const [blog, setBlog] = useState({});

  const getBlogDetail = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${blogDetail}`, {
        slug: `${slug}`,
      });
      if (res?.data?.success) {
        setBlog(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getBlogDetail();
  }, []);

  return (
    <WebLayout>
      <BlogDetailLanding blog={blog} />
      <BlogContent blog={blog} />
      <HomeCta title="We deliver our best to make your product better." />
    </WebLayout>
  );
};

export default BlogDetails;
